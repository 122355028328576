import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import classNames from 'classnames';
import Formsy from 'formsy-react';
import {usePaystackPayment} from 'react-paystack';
import {TextArea, TextInput} from '../../_components/CustomInput';
import {orderActions, cartActions} from '../../_actions';

const BillingDetails = ({setCartState, amount, cartList}) => {
   const dispatch = useDispatch();
   const {orderCreatedSuccessfully} = useSelector((s) => s.order);

   const [canSubmit, setCanSubmit] = useState(false);
   const [customer, setCustomer] = useState({});

   const meals = cartList.map((v) => ({
      meal_id: v._id,
      quantity: v.quantity,
      description: v.description,
      amount: v.amount,
      img_url: v.img_url,
      name: v.name,
   }));

   const config = {
      reference: new Date().getTime().toString(),
      email: customer.email,
      amount: amount * 100,
      // publicKey: 'pk_test_e31ee2dd90544209cd7ce196e3e411442f03b1a1',
      publicKey: 'pk_test_2e332edceb4697cbee8670a982a24449f5701087',
   };

   const initializePayment = usePaystackPayment(config);

   useEffect(() => {
      if (customer?.email !== '') {
         initializePayment(onSuccess, onClose);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [customer]);

   useEffect(() => {
      if (orderCreatedSuccessfully) {
         dispatch(cartActions.clearCart());
         setCartState(3);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [orderCreatedSuccessfully]);

   const onSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
      // console.log(reference);
      handleSuccessResponse(reference);
   };

   // you can call this function anything
   const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      // console.log('closed');
      setCustomer({});
   };

   const handleSuccessResponse = (response) => {
      const {reference} = response;
      const data = {
         total: amount,
         customer: {
            firstname: customer?.firstname,
            lastname: customer?.lastname,
            email: customer?.email,
            city: customer?.city,
            home_address: customer?.home_address,
            phone_number: customer?.phone_number,
         },
         is_paid: true,
         order_notes: customer?.order_notes,
         order_detail: meals,
         ref_no: reference,
      };
      // console.log('data', data);

      dispatch(orderActions.createOrder(data));
   };

   const handleSubmit = (data) => {
      setCustomer(data);
   };

   // 'd-none': !billing, 'd-flex': billing

   return (
      <div
         data-aos="fade-up"
         data-aos-duration="1000"
         className={classNames('billingDetails p-md-5 p-2 mt-4 d-flex flex-column', {})}>
         <p className="mb-4 fs-5 ps-md-0 ps-2">Billing Details</p>
         <Formsy
            className="__form"
            onValidSubmit={handleSubmit}
            onValid={() => setCanSubmit(true)}
            onInvalid={() => setCanSubmit(false)}>
            <div className="mb-4 row m-0 d-flex align-items-center justify-content-between">
               <TextInput
                  id="first_name"
                  type="text"
                  label="First Name"
                  name="firstname"
                  className="col-md-6 ps-md-0 mb-md-0 mb-3"
                  placeholder="e.g James Akinyemi"
                  value=""
                  required
               />
               <TextInput
                  id="last_name"
                  type="text"
                  label="Last Name"
                  name="lastname"
                  className="col-md-6 ps-md-4"
                  placeholder="e.g James Akinyemi"
                  value=""
                  required
               />
            </div>
            <div className="mb-4 row m-0 d-flex align-items-center justify-content-between">
               {/* <TextInput
                  id="company_name"
                  type="text"
                  label="Company Name (optional)"
                  name="company_name"
                  className="col-md-6 ps-0"
                  placeholder=""
                  value=""
                  // required
               /> */}
               <TextInput
                  id="email"
                  type="email"
                  label="Email Address"
                  name="email"
                  className="col-md-6 ps-md-0 mb-md-0 mb-3"
                  placeholder="e.g jamesakinyemi@gmail.com"
                  value=""
                  required
               />
               <TextInput
                  id="phone"
                  type="text"
                  label="Phone Number"
                  name="phone_number"
                  className="col-md-6 ps-md-4"
                  placeholder="e.g +234 000 000 0000"
                  required
               />
            </div>
            <div className="mb-4 row m-0 d-flex align-items-center justify-content-between">
               <TextInput
                  id="town_city"
                  type="text"
                  label="Town/City"
                  name="city"
                  className="col-md-6 ps-md-0 mb-md-0 mb-3"
                  placeholder=""
                  required
               />
               <TextInput
                  id="home_address"
                  type="text"
                  label="Home Address"
                  name="home_address"
                  className="col-md-6 ps-md-4"
                  placeholder=""
                  required
               />
            </div>
            <div className="mb-4 row m-0 d-flex align-items-center justify-content-between">
               <TextArea
                  id="order_notes"
                  type="text"
                  label="Notes about your order (optional)"
                  name="order_notes"
                  className="col-md-6 ps-md-0 mb-md-0 mb-3"
                  placeholder="Type in your notes e.g special notes for order"
                  value=""
                  rows={6}
               />
               {/* <TextInput
                  id="coupon"
                  type="text"
                  label="Have a coupon?"
                  name="coupon"
                  className="col-md-6 ps-md-4"
                  placeholder="Enter your code"
                  value=""
                  // required
               /> */}
            </div>

            <button type="submit" className="submit mt-md-4 mt-2 ms-md-0 ms-2" disabled={!canSubmit}>
               Place Order
            </button>
         </Formsy>
      </div>
   );
};

export default BillingDetails;
