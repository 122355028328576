import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BlogCard, BlogWrapper, Pagination} from '../../_components';
// import {blogPost} from '../../_constants';
import {blogActions} from '../../_actions';
import './index.scss';

const Blog = () => {
   const dispatch = useDispatch();
   const {IsRequestingBlogs, blogsList, pagination} = useSelector((s) => s.blog);
   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   useEffect(() => {
      dispatch(blogActions.getAllBlogs());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(blogActions.getAllBlogs(model));
   };

   return (
      <BlogWrapper>
         <div data-aos="fade-up" data-aos-duration="500" className="content blog container p-md-0 py-md-5 py-4 my-4">
            <h4 className="ps-md-4 ps-2">Popular posts</h4>
            <p className="subTitle py-3 ps-md-4 ps-2">Check out the trending news in the food world.</p>
            <div className="row m-0">
               {blogsList &&
                  blogsList.length > 0 &&
                  blogsList.map((posts) => (
                     <BlogCard data={posts} {...posts} className="trendingNews col-lg-4 col-sm-6 col-12" />
                  ))}
            </div>
         </div>
         {!IsRequestingBlogs && totalPages > 1 && (
            <Pagination
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </BlogWrapper>
   );
};

export default Blog;
