import {appConstants} from './app.constant';

const reservation = 'reservation';

export const reservationConstants = {
   REQUEST_CREATE_RESERVATION: 'REQUEST_CREATE_RESERVATION',
   CREATE_RESERVATION_SUCCESS: 'CREATE_RESERVATION_SUCCESS',
   CREATE_RESERVATION_ERROR: 'CREATE_RESERVATION_ERROR',

   CREATE_RESERVATION: 'CREATE_RESERVATION',

   RESERVATION_URI: `${appConstants.BASE_URI}${reservation}`,
};
