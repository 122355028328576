import {call, put, takeLatest, all} from 'redux-saga/effects';
import {reservationConstants} from '../../_constants';
import {appActions} from '../../_actions';
import {createRequest, checkStatus, parseJSON} from '../../_helpers';
//import crashlytics from '@react-native-firebase/crashlytics';

function* createReservationSaga({data}) {
   yield put({type: reservationConstants.REQUEST_CREATE_RESERVATION});

   try {
      const curriedReq = yield call(createRequest, reservationConstants.RESERVATION_URI, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      // yield call(setObjectInStorage, reservationConstants.reservation_KEY, jsonResponse);
      yield put({
         type: reservationConstants.CREATE_RESERVATION_SUCCESS,
         reservation: jsonResponse,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Reservation successfully made',
            variant: 'success',
         }),
      );
   } catch (error) {
      let errorRes;
      if (error.response) {
         errorRes = yield call(parseJSON, error.response);
      }
      const errorMessage = errorRes ? errorRes : error;
      yield put({type: reservationConstants.CREATE_RESERVATION_ERROR, error: errorMessage});
   }
}

function* createReservationSagaWatcher() {
   yield takeLatest(reservationConstants.CREATE_RESERVATION, createReservationSaga);
}

export default function* rootSaga() {
   yield all([createReservationSagaWatcher()]);
}
