import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {blogConstants} from '../../_constants';
import {checkStatus, createRequest, parseJSON} from '../../_helpers';

function* getBlogs({data}) {
   yield put({type: blogConstants.REQUEST_ALL_BLOGS});

   try {
      let blogsUri = `${blogConstants.BLOG_URI}`;

      if (data?.page) {
         blogsUri = `${blogsUri}?page=${data.page + 1}`;
      }
      const blogsReq = yield call(createRequest, blogsUri, {method: 'GET'});

      const response = yield call(fetch, blogsReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: blogConstants.REQUEST_ALL_BLOGS_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: blogConstants.REQUEST_ALL_BLOGS_SUCCESS,
         blogs: jsonResponse,
      });
   } catch (error) {
      yield put({type: blogConstants.REQUEST_ALL_BLOGS_ERROR});
   }
}

function* getRecentBlogs({data}) {
   yield put({type: blogConstants.REQUEST_RECENT_BLOGS});

   try {
      const blogsUri = `${blogConstants.BLOG_URI}?recent=true`;

      const blogsReq = yield call(createRequest, blogsUri, {method: 'GET'});

      const response = yield call(fetch, blogsReq);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: blogConstants.REQUEST_RECENT_BLOGS_SUCCESS,
         recentBlogs: jsonResponse,
      });
   } catch (error) {
      yield put({type: blogConstants.REQUEST_RECENT_BLOGS_ERROR});
   }
}

function* commentBlogSaga({data}) {
   yield put({type: blogConstants.REQUEST_COMMENT_BLOG});

   try {
      const commentUri = `${blogConstants.BLOG_URI}/${data.blogId}/comment`;

      const curriedReq = yield call(createRequest, commentUri, {
         method: 'PATCH',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: blogConstants.REQUEST_COMMENT_BLOG_SUCCESS,
         comment: jsonResponse,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Comment successfully posted',
            variant: 'success',
         }),
      );
   } catch (error) {
      let errorRes;
      if (error.response) {
         errorRes = yield call(parseJSON, error.response);
      }
      const errorMessage = errorRes ? errorRes : error;
      yield put({type: blogConstants.REQUEST_COMMENT_BLOG_ERROR, error: errorMessage});
   }
}

function* getBlogsWatcher() {
   yield takeLatest(blogConstants.GET_ALL_BLOGS, getBlogs);
}

function* getRecentBlogsWatcher() {
   yield takeLatest(blogConstants.GET_RECENT_BLOGS, getRecentBlogs);
}

function* commentBlogSagaWatcher() {
   yield takeLatest(blogConstants.COMMENT_BLOG, commentBlogSaga);
}

export default function* rootSaga() {
   yield all([getBlogsWatcher(), getRecentBlogsWatcher(), commentBlogSagaWatcher()]);
}
