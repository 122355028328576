import {combineReducers} from 'redux';
import {menuConstants} from '../../_constants';
import {updateObject} from '../../_helpers';

const IsRequestingAllMenus = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_ALL_MENUS:
         return true;
      case menuConstants.REQUEST_ALL_MENUS_ERROR:
         return false;
      case menuConstants.REQUEST_ALL_MENUS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingPopularMenus = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_POPULAR_MENUS:
         return true;
      case menuConstants.REQUEST_POPULAR_MENUS_ERROR:
         return false;
      case menuConstants.REQUEST_POPULAR_MENUS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingCategoryMenus = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_CATEGORY_MENUS:
         return true;
      case menuConstants.REQUEST_CATEGORY_MENUS_ERROR:
         return false;
      case menuConstants.REQUEST_CATEGORY_MENUS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingAMenu = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_A_MENU:
         return true;
      case menuConstants.REQUEST_A_MENU_ERROR:
         return false;
      case menuConstants.REQUEST_A_MENU_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsCommentingAMenu = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_COMMENT_MENU:
         return true;
      case menuConstants.REQUEST_COMMENT_MENU_ERROR:
         return false;
      case menuConstants.REQUEST_COMMENT_MENU_SUCCESS:
         return false;
      default:
         return state;
   }
};

const menuCommentSaved = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_COMMENT_MENU:
         return false;
      case menuConstants.REQUEST_COMMENT_MENU_ERROR:
         return false;
      case menuConstants.REQUEST_COMMENT_MENU_SUCCESS:
         return true;
      default:
         return state;
   }
};

const IsSearchingMenu = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_SEARCH_MENU:
         return true;
      case menuConstants.SEARCH_MENU_ERROR:
         return false;
      case menuConstants.SEARCH_MENU_SUCCESS:
         return false;
      default:
         return state;
   }
};

const menuDetails = (state = null, {type, menu, comment}) => {
   switch (type) {
      case menuConstants.REQUEST_A_MENU_SUCCESS:
         return updateObject(state, menu.data);
      case menuConstants.REQUEST_COMMENT_MENU_SUCCESS:
         return updateObject(state, comment.data);
      default:
         return state;
   }
};

const emptyState = {
   title: '',
   state_id: '',
   industry_id: '',
   specialization_id: '',
   closing_date: '',
   isPublished: '',
};

const menuList = (state = [], action) => {
   switch (action.type) {
      case menuConstants.REQUEST_ALL_MENUS_SUCCESS: {
         return action.menus.data;
      }
      case menuConstants.SEARCH_MENU_SUCCESS: {
         return action.menu.data.docs;
      }
      case menuConstants.SEARCH_MENU_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const popularMenusList = (state = [], action) => {
   switch (action.type) {
      case menuConstants.REQUEST_POPULAR_MENUS_SUCCESS: {
         return action.popularMenus.data;
      }
      default:
         return state;
   }
};

const categoryMenusList = (state = [], action) => {
   switch (action.type) {
      case menuConstants.REQUEST_ALL_MENUS_SUCCESS: {
         return action.menus.data;
      }
      case menuConstants.REQUEST_CATEGORY_MENUS_SUCCESS: {
         return action.categoryMenus.data.docs;
      }
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_CATEGORY_MENUS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} = action.categoryMenus.data;
         const result = {hasNextPage, nextPage, totalDocs, totalPages, limit, page};

         return updateObject(state, result);
      }
      case menuConstants.SEARCH_MENU_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, limit, page} = action.menu.data;
         const result = {hasNextPage, nextPage, totalDocs, limit, page};

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingAllMenus,
   IsRequestingPopularMenus,
   IsRequestingCategoryMenus,
   IsRequestingAMenu,
   IsCommentingAMenu,
   menuCommentSaved,
   IsSearchingMenu,
   menuDetails,
   menuList,
   popularMenusList,
   categoryMenusList,
   pagination,
});

export default rootReducer;
