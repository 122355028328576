import {useEffect, useState} from 'react';

// Adapted from https://javascript.plainenglish.io/custom-scrolling-direction-react-hook-f55558206ab6?source=search_post---------5

const UseScrollDirection = () => {
   const [scrollDirection, setScrollDirection] = useState(true);
   const [prevOffset, setPrevOffset] = useState(10);

   const toggleScrollDirection = () => {
      let scrollY = window.pageYOffset;

      if (scrollY === 0) {
         setScrollDirection(false);
      }
      if (scrollY > prevOffset) {
         setScrollDirection('down');
      } else if (scrollY < prevOffset) {
         setScrollDirection('up');
      }

      setPrevOffset(scrollY);
   };

   useEffect(() => {
      window.addEventListener('scroll', toggleScrollDirection);
      return () => {
         window.removeEventListener('scroll', toggleScrollDirection);
      };
   });

   return scrollDirection;
};

export default UseScrollDirection;

// HOW TO APPLY
// import UseScrollDirection
// const scrollDirection = UseScrollDirection('up');
// const styles = {
//    active: {
//       visibility: 'visible',
//       transition: 'all 0.5s',
//       transform: 'translateY(0%)',
//       // background: 'rgba(0, 0, 0, 0.5)',
//    },
//    hidden: {
//       visibility: 'hidden',
//       transition: 'all 0.5s',
//       transform: 'translateY(-100%)',
//    },
// };
//
// add the following line of code to the parent div element
// style={scrollDirection === 'down' ? styles.hidden : styles.active}
