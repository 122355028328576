import React, {useCallback, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {TextInput} from '../../_components';
import Formsy from 'formsy-react';
import {MDBCol} from 'mdb-react-ui-kit';
import {userConstants} from '../../_constants';
import {userActions} from '../../_actions';
import {Link} from 'react-router-dom';
import './index.scss';

// import CircularProgress from '@material-ui/core/CircularProgress';
// import logoImg from '../../_assets/images/logo.png';
// import {ReactComponent as Logo} from '../../_assets/icons/Logo.svg';
// import {ReactComponent as Logo} from '../../_assets/icons/new_logo.svg';
// import classNames from 'classnames';
// import {ReactComponent as Hide} from '../../_assets/icons/hide.svg';
// import {ReactComponent as Show} from '../../_assets/icons/show.svg';

const Signup = () => {
   const dispatch = useDispatch();
   const {IsRegisteringUser, userCreated, errorMessage} = useSelector((s) => s.user);
   let navigate = useNavigate();

   const [canSubmit, setCanSubmit] = useState(false);
   const [secure, setSecure] = useState(true);
   const [resecure, setResecure] = useState(true);
   const [passError, setPassError] = useState('');

   const toggleSecure = useCallback(() => {
      setSecure((prev) => !prev);
   }, []);
   const toggleResecure = useCallback(() => {
      setResecure((prev) => !prev);
   }, []);

   const disableButton = useCallback(() => {
      setCanSubmit(false);
   }, []);

   const enableButton = useCallback(() => {
      setCanSubmit(true);
   }, []);

   const handleSubmit = (data) => {
      data['role_id'] = userConstants.USER_ROLE_ID;

      if (data.confirmPassword === data.password) {
         delete data['confirmPassword'];
         dispatch(userActions.createUser(data));
      } else {
         setPassError('Passwords do not match');
      }
   };

   useEffect(() => {
      if (userCreated) {
         navigate('/verification');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userCreated]);

   return null;
};

export default Signup;
