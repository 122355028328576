import {call, put, takeLatest, all} from 'redux-saga/effects';
import {orderConstants} from '../../_constants';
import {appActions} from '../../_actions';
import {createRequest, checkStatus, parseJSON} from '../../_helpers';
//import crashlytics from '@react-native-firebase/crashlytics';

function* createOrderSaga({data}) {
   yield put({type: orderConstants.REQUEST_CREATE_ORDER});

   try {
      const curriedReq = yield call(createRequest, orderConstants.ORDER_URI, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      // yield call(setObjectInStorage, orderConstants.ORDER_KEY, jsonResponse);
      yield put({
         type: orderConstants.CREATE_ORDER_SUCCESS,
         order: jsonResponse,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Order successfully created',
            variant: 'success',
         }),
      );
   } catch (error) {
      let errorRes;
      if (error.response) {
         errorRes = yield call(parseJSON, error.response);
      }
      const errorMessage = errorRes ? errorRes : error;
      yield put({type: orderConstants.CREATE_ORDER_ERROR, error: errorMessage});
   }
}

function* createOrderSagaWatcher() {
   yield takeLatest(orderConstants.CREATE_ORDER, createOrderSaga);
}

export default function* rootSaga() {
   yield all([createOrderSagaWatcher()]);
}
