import React, {useCallback, useRef, useState} from 'react';
import Slider from 'react-slick';
import {useMediaQuery} from 'react-responsive';
import classNames from 'classnames';
import DishCards from '../DishCards';
import './index.scss';

import {ReactComponent as ArrowLeftIcon} from '../../_assets/icons/arrowLeft.svg';
import {ReactComponent as ArrowRightIcon} from '../../_assets/icons/arrowRight.svg';

const DishWrapper = ({data, history, className}) => {
   const slider = useRef();
   const [active, setActive] = useState(0);
   const mobileView = useMediaQuery({query: '(max-width: 767px)'});

   const prev = active === 0 || data.length <= 4;
   const next = data.length <= 4;
   const top = data.length - active <= 3;
   // console.log('slider data', {active: active + 1, prev, length: data.length});

   const settings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 2,
      initialSlide: 0,
      swipeToSlide: true,
      swipe: true,
      useTransform: true,
      responsive: [
         {
            breakpoint: 1130,
            settings: {
               slidesToShow: 4,
               slidesToScroll: 2,
            },
         },
         {
            breakpoint: 991,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 2,
            },
         },
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },
      ],
      beforeChange: (cr, nxt) => setActive(nxt),
   };

   const handleNext = useCallback(() => {
      slider.current.slickNext();
   }, []);

   const handlePrev = useCallback(() => {
      slider.current.slickPrev();
   }, []);

   const handleTop = useCallback(() => {
      slider.current.slickGoTo(0);
   }, []);

   const handleContextTop = useCallback((e) => {
      e.preventDefault();
      slider.current.slickGoTo(0);
   }, []);

   const handleContextBottom = useCallback(
      (e) => {
         e.preventDefault();
         if (!top) slider.current.slickGoTo(data.length - 1);
      },
      [data.length, top],
   );

   return (
      <div className="dishWrapper pe-0">
         <div className="position-relative">
            {!mobileView && (
               <div className="mb-4 mt-n4 d-flex align-items-center justify-content-end">
                  <div
                     onContextMenu={handleContextTop}
                     onClick={handlePrev}
                     className={classNames('prev me-4', {hidden: prev})}>
                     <ArrowLeftIcon width="1rem" />
                  </div>
                  <div
                     onContextMenu={handleContextBottom}
                     className={classNames(top ? 'top' : 'next', {hidden: next})}
                     onClick={top ? handleTop : handleNext}>
                     <ArrowRightIcon width="1rem" />
                  </div>
               </div>
            )}
            {!mobileView ? (
               <Slider ref={slider} {...settings}>
                  {data?.length > 0 &&
                     data?.map((itm, i) => {
                        return <DishCards data={itm} {...itm} key={i} className={className} />;
                     })}
               </Slider>
            ) : (
               <div className="caroosel overflow-hidden">
                  <div className="caroosel-inner align-items-center">
                     {data?.length > 0 &&
                        data?.map((itm, i) => {
                           return <DishCards data={itm} {...itm} key={i} className={className} />;
                        })}
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default DishWrapper;
