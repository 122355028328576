import {appConstants} from './app.constant';

const contact = 'contact';

export const contactConstants = {
   REQUEST_CONTACT_ADMIN: 'REQUEST_CONTACT_ADMIN',
   CONTACT_ADMIN_SUCCESS: 'CONTACT_ADMIN_SUCCESS',
   CONTACT_ADMIN_ERROR: 'CONTACT_ADMIN_ERROR',

   CONTACT_ADMIN: 'CONTACT_ADMIN',

   CONTACT_URI: `${appConstants.BASE_URI}${contact}`,
};
