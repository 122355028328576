import React from 'react';

const ImgWrapper = (props) => {
   return (
      <div data-aos="fade-up" className={`img position-relative ${props.className}`}>
         <img src={props.img} alt="" className="h-100 w-100" />
         {props.children && (
            <div className={`iconWrapper position-absolute ${props.iconWrapperClassName}`}>{props.children}</div>
         )}
      </div>
   );
};

export default ImgWrapper;
