import React from 'react';
import './index.scss';

// import aboutBannerImg from '../../../../_assets/images/aboutBannerImg.png';
import about1 from '../../../../_assets/images/about11.jpg';

const Section1 = () => {
   return (
      <section className="aboutSectionOne container-fluid p-0 position-relative overflow-hidden">
         <div className="bgOverlay position-absolute"></div>
         <div className="img position-absolute">
            <img src={about1} alt="" className="h-100 w-100" />
         </div>
         <div className="content container p-md-0 h-100">
            <div className="row m-0 h-100 d-flex align-items-center">
               <div className="col-md-8 col-12 offset-md-4">
                  <h1 data-aos="fade-up" data-aos-duration="1000" className="">
                     We Are A Modern Cafe and <br /> Restaurant In The Center Of The City
                  </h1>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Section1;
