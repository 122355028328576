import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import './index.scss';

import logoImg from '../../_assets/images/newLogo.png';

export default function Snackbar({message, variant, onClose, img}) {
   useEffect(() => {
      const timer = setTimeout(() => {
         onClose();
      }, 3000);
      return () => clearTimeout(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [message]);

   return (
      <div className={'notice p-3 d-flex align-items-center justify-content-center' + variant}>
         <div className="d-flex align-items-center" style={{textAlign: 'center'}}>
            <div className="img me-3 d-flex align-items-center justify-content-center">
               {img ? (
                  <img src={img} alt="" className="h-100 w-100" />
               ) : (
                  <img src={logoImg} alt="" className="" style={{transform: 'scale(.5)'}} />
               )}
            </div>
            <span className="me-4 text-truncate">{message}</span>
            {/* {message?.includes('cart') && ( */}
            {message?.includes('Order') && (
               <Link to="/cart" className="viewCart text-nowrap">
                  View cart
               </Link>
            )}
         </div>
      </div>
   );
}
