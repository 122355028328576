import {all} from 'redux-saga/effects';
import authenticationRootSaga from './auth.saga';
import blogRootSaga from './blog.saga';
import cartRootSaga from './cart.saga';
import contactRootSaga from './contact.saga';
import forgotPasswordRootSaga from './forgotPassword.saga';
import menuRootSaga from './menu.saga';
import orderRootSaga from './order.saga';
import reservationRootSaga from './reservation.saga';
import setupRootSaga from './setup.saga';
import userRootSaga from './user.saga';

export default function* rootSaga() {
   yield all([
      authenticationRootSaga(),
      blogRootSaga(),
      cartRootSaga(),
      contactRootSaga(),
      forgotPasswordRootSaga(),
      menuRootSaga(),
      orderRootSaga(),
      reservationRootSaga(),
      setupRootSaga(),
      userRootSaga(),
   ]);
}
