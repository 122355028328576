import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TextInput} from '../../_components';
import Formsy from 'formsy-react';
import {MDBCol} from 'mdb-react-ui-kit';
import classNames from 'classnames';
import {userConstants} from '../../_constants';
import {userActions} from '../../_actions';
import { Link } from 'react-router-dom';
import './index.scss';

// import logoImg from '../../_assets/images/logo.png';
// import {ReactComponent as Logo} from '../../_assets/icons/Logo.svg';
// import {ReactComponent as Logo} from '../../_assets/icons/new_logo.svg';
// import {ReactComponent as Tick} from '../../_assets/icons/tick.svg';

const Verification = () => {
   const dispatch = useDispatch();

   const [canSubmit, setCanSubmit] = useState(false);
   const [secure, setSecure] = useState(true);
   const [resecure, setResecure] = useState(true);
   const [open, setOpen] = useState(true);
   const toggle = useCallback(() => setOpen((prev) => !prev), []);
   const toggleSecure = useCallback(() => {
      setSecure((prev) => !prev);
   }, []);
   const toggleResecure = useCallback(() => {
      setResecure((prev) => !prev);
   }, []);

   const disableButton = useCallback(() => {
      setCanSubmit(false);
   }, []);

   const enableButton = useCallback(() => {
      setCanSubmit(true);
   }, []);

   const [active, setActive] = useState(1);
   const handleClick = useCallback((n) => {
      setActive((p) => {
         if (n !== p) {
            return n;
         }
         return p;
      });
   }, []);

   const handleSubmit = (data) => {
      data['role'] = userConstants.USER_ROLE_ID;

      dispatch(userActions.createUser(data));
   };

   return null;
};

export default Verification;
