import React from 'react';
import {DishCards} from '../../_components';

const RelatedDishes = ({data}) => {
   return (
      <div className="container py-5">
         <div className="relatedDishes">
            <h1 data-aos="fade-up" data-aos-duration="500" className="mb-4 ps-3">
               Other Dishes
            </h1>
            <div className="row m-0">
               {data.length > 0 &&
                  data.slice(0, 4).map((itm, i) => {
                     return (
                        <DishCards
                           data={itm}
                           {...itm}
                           key={i}
                           className="col-lg-3 col-md-4 col-sm-6 col-12 p-3 mb-md-0 mb-4 popular"
                        />
                     );
                  })}
            </div>
         </div>
      </div>
   );
};

export default RelatedDishes;
