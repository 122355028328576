import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {menu} from '../../_constants';
import {useLandingPageRoute, useMatch} from '../../_helpers/hooks';
import useOnClickOutside from '../../_components/onClickOutside';
import {Reservations} from '../../_components';
import './index.scss';

import logo from '../../_assets/images/newLogo.png';
import {ReactComponent as CartIcon} from '../../_assets/icons/cart.svg';
import {ReactComponent as BurgerMenuIcon} from '../../_assets/icons/burger-menu.svg';
import {ReactComponent as CloseIcon} from '../../_assets/icons/close-x.svg';

const Navbar = () => {
   const mobileMenuRef = useRef();
   const code = useLandingPageRoute();
   const aboutRoute = useMatch('/about');
   const cartList = useSelector((s) => s.cart.cartList);

   // const cartRoute = useMatch('/cart');
   const [modalOpen, setModalOpen] = useState(false);
   const [mobileMenu, setMobileMenu] = useState(false);
   const [backgroundColor, setBackgroundColor] = useState(false);

   useOnClickOutside(mobileMenuRef, () => {
      if (mobileMenu) setMobileMenu(false);
   });

   const activeRoute = useCallback(
      (num) => {
         return code === num;
      },
      [code],
   );

   const changeNavbarColor = () => {
      if (window.scrollY >= 5) {
         setBackgroundColor(true);
      } else {
         setBackgroundColor(false);
      }
   };

   useEffect(() => {
      window.addEventListener('scroll', changeNavbarColor);
      return () => {
         window.removeEventListener('scroll', changeNavbarColor);
      };
   }, []);

   return (
      <div
         className={classNames('__navbar container-fluid position-fixed d-flex align-items-center', {
            whiteBg: !!backgroundColor,
         })}>
         <div className="content h-100 container p-md-0 d-flex align-items-center justify-content-between">
            <div className="logo d-flex align-items-center justify-content-start">
               <Link to="/" className="">
                  <div data-aos="zoom-in" className="img">
                     <img src={logo} alt="" className="w-100 h-100" />
                  </div>
               </Link>
            </div>
            <ul className="menu h-100 d-none d-md-flex align-items-center">
               {menu.map((item) => (
                  <Link
                     key={`menu-item-${item.val + 1}`}
                     to={item.to}
                     data-aos="zoom-in"
                     onClick={() => {}}
                     className={classNames('mx-3 text-nowrap h-100', {
                        active: activeRoute(item.activeCode),
                        color: !!aboutRoute,
                        back: !!aboutRoute && !!backgroundColor,
                     })}>
                     <li className="text-capitalize h-100 d-flex align-items-center">{item.label}</li>
                  </Link>
               ))}
            </ul>
            <div className="action d-flex align-items-center justify-content-end">
               <Link to="/cart">
                  <div
                     data-aos="zoom-in"
                     className="cart me-lg-5 me-4 position-relative d-flex align-items-center justify-content-center">
                     <CartIcon
                        className={classNames('cartIcon h-100 w-100', {
                           active: activeRoute(6),
                           color: aboutRoute,
                           back: !!aboutRoute && !!backgroundColor,
                        })}
                     />
                     <div className="no position-absolute d-flex align-items-center justify-content-center">
                        {cartList?.length}
                     </div>
                  </div>
               </Link>
               <BurgerMenuIcon
                  data-aos="zoom-in"
                  onClick={() => setMobileMenu(true)}
                  className={classNames('burgerIcon d-md-none d-inline-flex', {
                     color: aboutRoute,
                     back: !!aboutRoute && !!backgroundColor,
                  })}
               />
               <button data-aos="zoom-in" onClick={() => setModalOpen(true)} className="reservations d-none d-md-flex">
                  Reservations
               </button>
               <Reservations modalOpen={modalOpen} setModalOpen={setModalOpen} />
            </div>
         </div>

         <div ref={mobileMenuRef} className={classNames('mobileMenu position-absolute', `${mobileMenu && 'showMenu'}`)}>
            <div className="pt-2 pe-2 d-flex justify-content-end">
               <CloseIcon
                  className=""
                  onClick={() => setMobileMenu(false)}
                  style={{transform: 'scale(1)', color: '#545F8A', cursor: 'pointer'}}
               />
            </div>
            <div className="content px-4 pb-4">
               <div className="logo mb-4 w-100 d-flex align-items-center justify-content-center">
                  <Link to="/" className="" onClick={() => setMobileMenu(false)}>
                     <div data-aos="fade-up" className="img">
                        <img src={logo} alt="" className="w-100 h-100" />
                     </div>
                  </Link>
               </div>
               <ul className="menu d-flex flex-column align-items-center">
                  {menu.map((item) => (
                     <Link
                        key={`menu-item-${item.val + 1}`}
                        to={item.to}
                        data-aos="fade-up"
                        onClick={() => setMobileMenu(false)}
                        className={classNames('mx-3 mb-3 text-nowrap', {active: activeRoute(item.activeCode)})}>
                        <li className="text-capitalize">{item.label}</li>
                     </Link>
                  ))}
               </ul>
               <div className="d-flex justify-content-center">
                  <button data-aos="fade-up" onClick={() => setModalOpen(true)} className="reservations">
                     Reservations
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Navbar;
