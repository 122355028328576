import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Formsy from 'formsy-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import FullscreenModal from '../..';
import {TextArea, TextInput} from '../../../CustomInput';
import './index.scss';
import {capitalizeFirstLetter, capitalizeFirstWord} from '../../../../_helpers';

import {ReactComponent as CloseIcon} from '../../../../_assets/icons/close.svg';
import {reservationActions} from '../../../../_actions';

const Reservations = ({modalOpen, setModalOpen}) => {
   const dispatch = useDispatch();
   const {IsCreatingReservation, reservationCreatedSuccessfully, errorMessage} = useSelector((s) => s.reservation);

   const [canSubmit, setCanSubmit] = useState(false);
   const [facility, setFacility] = useState('table');

   useEffect(() => {
      if (reservationCreatedSuccessfully) {
         setModalOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reservationCreatedSuccessfully]);

   const handleSubmit = (data) => {
      data['guests'] = Number(data.seats);
      data['facility'] = facility;
      delete data['seats'];

      dispatch(reservationActions.createReservation(data));
   };
   console.log('error', errorMessage);
   const fullnameError =
      errorMessage?.message.length > 0 && errorMessage?.message?.filter((error) => error.split(' ')[0] === 'fullname');
   const emailError =
      errorMessage?.message.length > 0 && errorMessage?.message?.filter((error) => error.split(' ')[0] === 'email');
   const phoneError =
      errorMessage?.message.length > 0 && errorMessage?.message?.filter((error) => error.split(' ')[0] === 'phone_no');
   const dateError =
      errorMessage?.message.length > 0 && errorMessage?.message?.filter((error) => error.split(' ')[0] === 'date');
   const purposeError =
      errorMessage?.message.length > 0 && errorMessage?.message?.filter((error) => error.split(' ')[0] === 'purpose');
   const guestsError =
      errorMessage?.message.length > 0 && errorMessage?.message?.filter((error) => error.split(' ')[0] === 'guests');
   const specialRequestError = errorMessage?.message?.filter((error) => error.split(' ')[0] === 'special_request');

   return (
      <FullscreenModal open={modalOpen} onClickAway={() => setModalOpen(false)}>
         <div className="reservations p-md-5 p-3 position-relative">
            <div className="position-absolute" style={{top: '1rem', right: '1.5rem'}}>
               <CloseIcon onClick={() => setModalOpen(false)} style={{transform: 'scale(.7)', cursor: 'pointer'}} />
            </div>
            <h6 className="">Make your Reservations</h6>
            <h4 className="py-4">Booking Order</h4>
            <Formsy
               onValidSubmit={handleSubmit}
               onValid={() => setCanSubmit(true)}
               onInvalid={() => setCanSubmit(false)}
               className="__form">
               <p className="error-text d-flex align-items-center">
                  {capitalizeFirstLetter(errorMessage?.message?.[0] || errorMessage?.message)}
               </p>
               <div className="mb-4 row m-0 d-flex align-items-center justify-content-between">
                  <TextInput
                     id="name"
                     type="text"
                     label="Full Name"
                     name="fullname"
                     className="col-md-6 px-md-3 px-0 ps-md-0 mb-md-0 mb-3"
                     placeholder="e.g James Akinyemi"
                     required
                  />
                  <TextInput
                     id="email"
                     type="email"
                     label="Email Address"
                     name="email"
                     className="col-md-6 px-md-3 px-0 pe-md-0"
                     placeholder="e.g jamesakinyemi@gmail.com"
                     required
                  />
               </div>
               <p className="error-text">{fullnameError?.length > 0 && capitalizeFirstWord(fullnameError?.[0])}</p>
               <p className="error-text">{emailError?.length > 0 && capitalizeFirstWord(emailError?.[0])}</p>
               <div className="mb-4 row m-0 d-flex align-items-enter justify-content-between">
                  <TextInput
                     id="number"
                     type="text"
                     label="Phone Number"
                     name="phone_no"
                     className="col-md-6 px-md-3 px-0 ps-md-0 mb-md-0 mb-3"
                     placeholder=""
                     required
                  />
                  <TextInput
                     id="time"
                     type="time"
                     label="Time"
                     name="time"
                     className="col-md-6 px-md-3 px-0 pe-md-0"
                     placeholder=""
                     required
                  />
               </div>
               <p className="error-text">{phoneError?.length > 0 && capitalizeFirstWord(phoneError?.[0])}</p>
               <div className="mb-4 row m-0 d-flex align-items-enter justify-content-between">
                  <TextInput
                     id="date"
                     type="date"
                     label="Date"
                     name="date"
                     className="col-md-6 px-md-3 px-0 ps-md-0 mb-md-0 mb-3"
                     placeholder="MM/DD/YY"
                     required
                  />
                  <TextInput
                     id="seats"
                     type="number"
                     label="No. of Seats"
                     name="seats"
                     className="col-md-6 px-md-3 px-0 pe-md-0"
                     placeholder=""
                     required
                     errorMessage={guestsError?.length > 0 && capitalizeFirstWord(guestsError?.[0])}
                  />
               </div>
               {/* <p className="error-text">{guestsError?.length > 0 && capitalizeFirstWord(guestsError?.[0])}</p> */}
               <p className="error-text">{dateError?.length > 0 && capitalizeFirstWord(dateError?.[0])}</p>
               <div className="mb-4 row m-0 d-flex align-items-enter justify-content-between">
                  <TextInput
                     id="purpose"
                     type="text"
                     label="Purpose"
                     name="purpose"
                     className="col-md-6 px-md-3 px-0 ps-md-0 mb-md-0 mb-3"
                     placeholder=""
                     required
                  />
                  <div className="col-md-6 px-md-3 px-0 pe-md-0">
                     <label for="cars" className="d-flex">
                        Facility:
                     </label>
                     <select
                        name="facility"
                        className="w-100"
                        id="facility"
                        onChange={(e) => setFacility(e.target.value)}>
                        <option value={'table'}>Table</option>
                        <option value={'event'}>Event</option>
                     </select>
                  </div>
               </div>
               <p className="error-text">{purposeError?.length > 0 && capitalizeFirstWord(purposeError?.[0])}</p>
               <TextArea
                  id="review"
                  type="text"
                  label="Special Request"
                  name="special_request"
                  className="mb-4"
                  placeholder="Type in your special request"
                  rows={6}
               />
               <p className="error-text">
                  {specialRequestError?.length > 0 && capitalizeFirstWord(specialRequestError?.[0])}
               </p>
               <button type="submit" className="submit w-100" disabled={!canSubmit}>
                  {IsCreatingReservation ? <CircularProgress color="inherit" thickness={1} /> : 'Book Now'}
               </button>
            </Formsy>
         </div>
      </FullscreenModal>
   );
};

export default Reservations;
