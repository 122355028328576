import {cartConstants} from '../_constants';

const getAllCartItems = (model) => ({
   type: cartConstants.GET_ALL_CART_ITEMS,
   data: model,
});

const addToCart = (model) => ({
   type: cartConstants.ADD_TO_CART,
   data: model,
});

const updateItemQuantityInCart = (model) => ({
   type: cartConstants.UPDATE_ITEM_QUANTITY_IN_CART,
   data: model,
});

const removeFromCart = (model) => ({
   type: cartConstants.REMOVE_FROM_CART,
   data: model,
});

const clearCart = (model) => ({
   type: cartConstants.CLEAR_CART,
   data: model,
});

export const cartActions = {
   getAllCartItems,
   addToCart,
   updateItemQuantityInCart,
   removeFromCart,
   clearCart,
};
