import React from 'react';
import './index.scss';

import about4 from '../../../../_assets/images/about44.jpg';
import about5 from '../../../../_assets/images/about55.jpg';
import about7 from '../../../../_assets/images/new-about.png';
import {ReactComponent as BgIcon} from '../../../../_assets/icons/rollArrow.svg';

const Section4 = () => {
   return (
      <section className="aboutSectionFour container-fluid p-0 pt-5 pb-5 overflow-hidden">
         <div className="content container px-0 mb-5 position-relative">
            <div className="bgImg position-absolute">
               <img src={about7} alt="" className="w-100 h-100" />
            </div>
            <div className="bg p-md-5 p-3 position-relative">
               <BgIcon className="bgIcon position-absolute" />
               <h2 data-aos="fade-up" data-aos-duration="500" className="text-center pt-4 pb-md-4 pb-2">
                  The Birth of Lagos Tea House
               </h2>
               <div className="row m-0 py-md-5 py-3">
                  <div className="col-lg-6 col-12 pb-lg-0 pb-5 px-lg-5 d-flex align-items-center justify-content-center">
                     <p data-aos="fade-up" data-aos-duration="500" className="">
                        While on a trip to shanghai, China, in 2011, I noticed an overwhelming presence of tea rooms and
                        tea houses that lined numerous streets, malls and hotel lobbies throughout the city. As my trip
                        went on, I also noticed how physically and mentally fit the Chinese are, even the elderly! One
                        day I decided to take some time out of my busy itinerary to visit one of the tea houses. During
                        my visit, I had the privilege of speaking with a tea sommelier, who captivated me with his
                        profound knowledge of various teas and their associated health benefits.
                     </p>
                  </div>
                  <div className="col-lg-6 col-12 d-flex align-items-center justify-content-center">
                     <div data-aos="fade-up" data-aos-duration="500" className="imgBg">
                        <img src={about4} alt="" className="h-100 w-100" />
                     </div>
                  </div>
               </div>
               <div className="row m-0 py-md-5 py-3">
                  <div className="col-lg-6 col-12 order-lg-1 order-2 pt-lg-0 pt-5 d-flex align-items-center justify-content-center">
                     <div data-aos="fade-up" data-aos-duration="500" className="imgBg">
                        <img src={about5} alt="" className="h-100 w-100" />
                     </div>
                  </div>
                  <div className="col-lg-6 col-12 order-lg-2 order-1 px-md-5 pt-lg-0 pt-5 d-flex align-items-center justify-content-center">
                     <p data-aos="fade-up" data-aos-duration="500" className="">
                        He also showed me how to pair different types of teas with various meals in order to achieve a
                        flavorful aftertaste and complete absorption of the teas. I instantly fell in love with teas and
                        immediately made the decision to create a tea house in Nigeria where people could DRINK HEALTHY
                        AND EAT HEALTHY. My dream was realized almost three years later when LAGOS TEA HOUSE opened its
                        doors to tea lovers and connoisseurs, as well as baked goods and healthy food enthusiasts.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Section4;
