import React from 'react';
import classNames from 'classnames';
// import { useLandingPageRoute } from '../../_helpers';
// import { Link } from 'react-router-dom';
import './index.scss';

import {ReactComponent as LinkedInIcon} from '../../_assets/icons/linkedin.svg';
import {ReactComponent as FacebookIcon} from '../../_assets/icons/facebook.svg';
import {ReactComponent as TwitterIcon} from '../../_assets/icons/twitter.svg';
import {ReactComponent as InstagramIcon} from '../../_assets/icons/instagram.svg';
import {ReactComponent as PhoneIcon} from '../../_assets/icons/phone.svg';
import {ReactComponent as LocationIcon} from '../../_assets/icons/location.svg';
import {ReactComponent as MailIcon} from '../../_assets/icons/mail.svg';
import {Link} from 'react-router-dom';

const Footer = () => {
   return (
      <div className={classNames('__footer container-fluid position-relative d-flex align-items-center', {})}>
         <div className="content container p-md-0">
            <div className="row m-0 py-md-0 py-4">
               <div className="col-md-4 col-12 text-md-start text-center p-0 pe-md-5 pb-md-0 pb-4">
                  <h4 className="text-uppercase">lagos tea house</h4>
                  <p className="py-md-4 py-2 pe-md-3">
                     We are a modern restaurant and tea café that offer a wide selection of food options and amazing tea
                     creations to please all palates.
                  </p>
                  <ul className="socialMedia pt-2 d-flex align-items-center justify-content-md-start justify-content-center">
                     {/* <a href="https://linkedin.com/" target="_blank" className="me-3" rel="noreferrer">
                        <li className="">
                           <LinkedInIcon className="icon" />
                        </li>
                     </a> */}
                     <a href="https://www.facebook.com/lagosteahouse" target="_blank" className="me-3" rel="noreferrer">
                        <li className="">
                           <FacebookIcon className="icon" />
                        </li>
                     </a>
                     <a href="https://twitter.com/lagosteahouse" target="_blank" className="me-3" rel="noreferrer">
                        <li className="">
                           <TwitterIcon className="icon" />
                        </li>
                     </a>
                     <a href="https://instagram.com/teahouselagos" target="_blank" className="me-3" rel="noreferrer">
                        <li className="">
                           <InstagramIcon className="icon" />
                        </li>
                     </a>
                  </ul>
               </div>

               <div className="col-md-4 col-12 p-0 px-lg-5 py-md-0 py-4">
               {/* <div className="col-md-4 col-12 p-0 py-md-0 py-4"> */}
                  <ul className="lists text-md-start text-center ps-lg-5 ps-md-0 ps-3">
                     {/* <li className="">
                        <a href="/" className="text-capitalize text-nowrap">
                           work station
                        </a>
                     </li> */}
                     <li className="">
                        <Link to="/about" className="text-capitalize text-nowrap">
                           about us
                        </Link>
                     </li>
                     <li className="">
                        <Link to="/menu" className="text-capitalize text-nowrap">
                           our menu
                        </Link>
                     </li>
                     <li className="">
                        <Link to="/blog" className="text-capitalize text-nowrap">
                           our blog
                        </Link>
                     </li>
                     <li className="">
                        {/* <a href="/" className="text-capitalize text-nowrap">
                           terms
                        </a> */}
                     </li>
                     <li className="">
                        {/* <a href="/" className="text-capitalize text-nowrap">
                           locations
                        </a> */}
                     </li>
                  </ul>
               </div>

               <div className="col-md-4 col-12 text-md-start text-center p-0 ps-md-5 pb-md-0 pb-4">
                  <div className="ps-md-4">
                     <h4 className="text-capitalize mb-md-4 mb-3">contact us</h4>
                     <ul className="contact">
                        <li className="d-flex align-items-start justify-content-md-start justify-content-center">
                           <div className="icon me-md-3 me-2">
                              <PhoneIcon className="h-100 w-100" />
                           </div>
                           <a href="tel:+2348187934282" className="text-decoration-none">
                              +234 818 793 4282
                           </a>
                        </li>
                        <li className="d-flex align-items-start justify-content-md-start justify-content-center">
                           <div className="icon2 me-md-4 me-md-3 me-2">
                              <LocationIcon className="h-100 w-100" />
                           </div>
                           <p className="text-md-start text-center">
                              Lagos Tea House, Plot 6, Admiralty Way Lekki Phase 1, (the ash building beside UBA and
                              packtonian hotel) Lagos, Nigeria.
                           </p>
                        </li>
                        <li className="d-flex align-items-start justify-content-md-start justify-content-center">
                           <div className="icon me-md-3 me-2">
                              <MailIcon className="h-100 w-100" />
                           </div>
                           <a href="mailto:lagosteahouse@gmail.com" className="text-decoration-none">
                              lagosteahouse@gmail.com
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <footer className="pt-lg-4 pt-0 pb-md-0 pb-4 d-flex align-items-center justify-content-center">
               Copyrights<span className="">&copy;</span> {new Date().getFullYear()}. Lagos Tea House
            </footer>
         </div>
      </div>
   );
};

export default Footer;
