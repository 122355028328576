// const base_uri = 'https://lagos-tea-house.herokuapp.com/api/v1/';
const base_uri = 'https://api.lagosteahouse.com/api/v1/';

export const appConstants = {
   BASE_URI: base_uri,

   BANK_KEY: 'bank',
   CATEGORY_KEY: 'category',

   CLEAR_SNACKBAR: 'CLEAR_SNACKBAR',
   SET_SNACKBAR: 'SET_SNACKBAR',
   CLEAR_MESSAGES: 'CLEAR_MESSAGES',

   GET_STATES: 'GET_STATES',
   GET_STATE_DATA: 'GET_STATE_DATA',
   GET_STATE_DATA_FROM_STORAGE: 'GET_STATE_DATA_FROM_STORAGE',

   REQUEST_ALL_STATES: 'REQUEST_ALL_STATES',
   REQUEST_ALL_STATES_SUCCESS: 'REQUEST_ALL_STATES_SUCCESS',
   REQUEST_ALL_STATES_ERROR: 'REQUEST_ALL_STATES_ERROR',

   REQUEST_STATE_DATA: 'REQUEST_STATE_DATA',
   REQUEST_STATE_DATA_SUCCESS: 'REQUEST_STATE_DATA_SUCCESS',
   REQUEST_STATE_DATA_ERROR: 'REQUEST_STATE_DATA_ERROR',

   REQUEST_STATE_DATA_FROM_STORAGE: 'REQUEST_STATE_DATA_FROM_STORAGE',
   REQUEST_STATE_DATA_FROM_STORAGE_ERROR: 'REQUEST_STATE_DATA_FROM_STORAGE_ERROR',
   REQUEST_STATE_DATA_FROM_STORAGE_SUCCESS: 'REQUEST_STATE_DATA_FROM_STORAGE_SUCCESS',

   BANK_URL: `${base_uri}bank/AllAccounts`,
   CATEGORY_URL: `${base_uri}category`,
   STATE_DATA_URL: `${base_uri}state_institution_stats`,
};
