import React from 'react';
import {ImgWrapper} from '../../../../_components';
import './index.scss';

import about3 from '../../../../_assets/images/about33.jpg';
import {ReactComponent as StarIcon} from '../../../../_assets/icons/greenStar.svg';

const Section3 = () => {
   return (
      <section className="aboutSectionThree container-fluid p-0 overflow-hidden">
         <div className="content container h-100 p-md-0 pt-md-4 pt-4">
            <div className="row m-0">
               <div className="col-lg-6 col-12 order-md-1 order-2 pt-md-0 pt-4 p-lg-5 p-md-1 px-0 d-flex align-items-center justify-content-center">
                  <div className="p-lg-4 p-md-0 pt-4 px-0 position-relative">
                     <ImgWrapper img={about3} className="img">
                        <StarIcon className="starIcon" />
                     </ImgWrapper>
                  </div>
               </div>
               <div className="col-lg-6 col-12 order-md-2 order-1 pt-md-0 pt-4 d-flex align-items-center justify-content-center">
                  <p data-aos="fade-up" data-aos-duration="1000" className="">
                     Lagos Tea House is a contemporary café and restaurant which delights in serving healthy Teas from
                     our over 50 assorted loose leaves Teas, coffees, smoothies, juices, and of course very tasty and
                     yummy food and bakes. <br /> Here at Lagos Tea House, we ensure that the right quantity and quality
                     of Teas are brewed using the right temperatures. <br /> Lagos Tea House sources it's Teas and other
                     ingredients from the best organic farms around the globe because we desire nothing but the best for
                     our guest. <br /> As we keep growing, our mission expands ensuring that healthy foods and drinks
                     are accessible to all.
                  </p>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Section3;
