import React from 'react';
import {useSelector} from 'react-redux';
import {DishWrapper} from '../../../../_components';
// import {popularDishes} from '../../../../_constants';
import './index.scss';

import {ReactComponent as BubblesIcon} from '../../../../_assets/icons/bubbles.svg';

const Section3 = () => {
   const popularMenusList = useSelector((s) => s.menu.popularMenusList);
   // console.log('popularMenusList', popularMenusList);

   return (
      <section className="sectionThree container-fluid p-0 position-relative overflow-hidden">
         <div className="content container pt-md-4 pt-4 px-md-0 pb-md-5 pb-4 mb-4">
            <h6 data-aos="zoom-in" className="text-uppercase pb-md-4 pb-2 ps-md-2 ps-2">
               our menu
            </h6>
            <h1
               data-aos="zoom-in"
               // data-aos-duration="700"
               className="ps-md-2 ps-2 d-inline-flex text-uppercase position-relative">
               popular dishes
               <div className="bubbles position-absolute">
                  <BubblesIcon className="icon" />
               </div>
            </h1>
            <div className="row m-0 banner pt-md-0 pt-4">
               <DishWrapper data={popularMenusList} className="col-md-3 col-6 w-100 p-3 popular" />
            </div>
         </div>
      </section>
   );
};

export default Section3;
