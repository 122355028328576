import {orderConstants} from '../_constants';

const createOrder = (model) => ({
   type: orderConstants.CREATE_ORDER,
   data: model,
});

export const orderActions = {
   createOrder,
};
