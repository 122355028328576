import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {TextInput} from '../../_components';
import Formsy from 'formsy-react';
import {MDBCol} from 'mdb-react-ui-kit';
import {capitalizeFirstLetter} from '../../_helpers';
import {userConstants} from '../../_constants';
import {userActions} from '../../_actions';
import './index.scss';

// import CircularProgress from '@material-ui/core/CircularProgress';
// import logoImg from '../../_assets/images/logo.png';
// import {ReactComponent as Logo} from '../../_assets/icons/new_logo.svg';

const Login = ({location, history}) => {
   const dispatch = useDispatch();
   const {isRequestingUser, info} = useSelector((s) => s.user);
   const {IsRequestingLogin, IsRequestingLogout, errorMessage} = useSelector((s) => s.auth);

   const [canSubmit, setCanSubmit] = useState(false);
   // const [secure, setSecure] = useState(true);

   // const toggleSecure = useCallback(() => {
   //    setSecure((prev) => !prev);
   // }, []);

   const disableButton = useCallback(() => {
      setCanSubmit(false);
   }, []);

   const enableButton = useCallback(() => {
      setCanSubmit(true);
   }, []);

   if (isRequestingUser) {
      return null;
   }
   if (IsRequestingLogout) {
      return null;
   }
   if (info) {
      if (location.state !== undefined) {
         history.push(location.state.from.pathname);
      } else {
         history.push('/dashboard');
      }
   }

   const handleSubmit = (data) => {
      data['role_id'] = userConstants.USER_ROLE_ID;

      dispatch(userActions.login(data));
   };

   return null;
};

export default Login;
