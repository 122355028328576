import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {DishCards, Pagination} from '../../_components';
import './index.scss';
import {appActions, menuActions} from '../../_actions';

import menu1 from '../../_assets/images/menu1.jpg';
import {capitalizeFirstLetter} from '../../_helpers';

const Menu = () => {
   const dispatch = useDispatch();
   const allCategories = useSelector((s) => s.setup.allCategories);
   const {orderCreatedSuccessfully} = useSelector((s) => s.order);
   const {IsRequestingCategoryMenus, categoryMenusList, pagination} = useSelector((s) => s.menu);
   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   const [active, setActive] = useState('');
   // const {_id, name, description} = allCategories?.[active];
   const _id = allCategories?.[active]?._id;
   const name = allCategories?.[active]?.name;
   const description = allCategories?.[active]?.description;

   useEffect(() => {
      dispatch(menuActions.getAllMenus());
      if (orderCreatedSuccessfully) {
         dispatch(appActions.clearMessages());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleCategoryMenu = (data, index) => {
      setActive(index);
      const model = {
         _id: data,
      };
      dispatch(menuActions.getCategoryMenu(model));
   };

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
         _id: _id,
      };

      dispatch(menuActions.getCategoryMenu(model));
   };
   const getAllMenus = () => {
      dispatch(menuActions.getAllMenus());
   };

   return (
      <>
         <section className="menuSectionOne container-fluid p-0 position-relative overflow-hidden">
            <div className="bgOverlay position-absolute"></div>
            <div className="img position-absolute">
               <img src={menu1} alt="" className="h-100 w-100" />
            </div>
            <div
               data-aos="zoom-in"
               data-aos-duration="500"
               className="content container p-md-0 h-100 d-flex align-items-center justify-content-center">
               <p className="text-capitalize">our menu</p>
            </div>
         </section>

         <section className="menuSectionTwo container-fluid p-0 position-relative overflow-hidden">
            <div className="content container p-md-0 py-md-5 py-4 my-4">
               <div className="row m-0 h-100">
                  <div className="col-md-4 col-12 pe-lg-4">
                     <div data-aos="fade-up" data-aos-duration="500" className="categories p-md-4 p-2">
                        <p className="text-center cat py-md-3 pb-md-4 pb-2" onClick={() => getAllMenus()}>
                           Categories
                        </p>
                        <ul className="w-100">
                           {allCategories?.length > 0 &&
                              allCategories.map((item, index) => (
                                 <li
                                    key={`categories-${item._id}`}
                                    onClick={() => handleCategoryMenu(item._id, index)}
                                    className={classNames(
                                       'p-md-3 px-2 mb-md-3 mx-md-0 mx-2 d-flex align-items-center',
                                       {
                                          active: active === index,
                                       },
                                    )}>
                                    <div className="img me-md-2 me-2 d-none d-lg-flex">
                                       <img src={item.img_url} alt="" className="" />
                                    </div>
                                    <div
                                       data-toggle="tooltip"
                                       data-placement="top"
                                       title={item.name}
                                       className="label text-truncate text-capitalize me-lg-2 text-nowrap">
                                       {capitalizeFirstLetter(item.name)}
                                    </div>
                                    <span className="number">{item.totalMeals}</span>
                                 </li>
                              ))}
                        </ul>
                     </div>
                     {/* <div className="comments py-5 d-none d-md-flex">
                        <p className="">Comments</p>
                        <ul className="w-100">
                           {comments.map((item, index) => (
                              <li key={`categories-${item.id}`} className="p-3 d-flex align-items-center">
                                 <div className="img me-2">
                                    <img src={item.img} alt="" className="" />
                                 </div>
                                 <div className="label text-capitalize">{item.label}</div>
                                 <span className="">{item.noOfItem}</span>
                              </li>
                           ))}
                        </ul>
                     </div> */}
                  </div>

                  <div
                     key={_id}
                     data-aos="fade-up"
                     data-aos-duration="500"
                     className="food pt-md-0 pt-4 col-md-8 col-12 px-0">
                     <h3 className="title ps-2 text-capitalize">{name || 'Our Menu'}</h3>
                     <p className="subTitle ps-2 py-md-4 py-1">
                        {description || 'Explore Our Sumptuous Meals And Delicacies At Affordable Prices'}
                     </p>
                     <div className="row m-0 mt-3">
                        {categoryMenusList?.length > 0 ? (
                           categoryMenusList.map((item) => (
                              <DishCards data={item} {...item} className="col-lg-4 col-sm-6 col-12 mb-5" />
                           ))
                        ) : (
                           <h3>No available meal under this category</h3>
                        )}
                     </div>
                     {!IsRequestingCategoryMenus && totalPages > 1 && (
                        <Pagination
                           rowsPerPageOptions={[]}
                           colSpan={2}
                           count={total}
                           rowsPerPage={limit}
                           page={pages}
                           totalPages={totalPages}
                           onChangePage={handleChangePage}
                        />
                     )}
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Menu;
