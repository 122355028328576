import {menuConstants} from '../_constants';

const getAllMenus = (model) => ({
   type: menuConstants.GET_ALL_MENUS,
   data: model,
});

const getPopularMenus = () => ({
   type: menuConstants.GET_POPULAR_MENUS,
});

const getCategoryMenu = (model) => ({
   type: menuConstants.GET_CATEGORY_MENUS,
   data: model,
});

const getAMenu = (model) => ({
   type: menuConstants.GET_A_MENU,
   data: model,
});

const commentMenu = (model) => ({
   type: menuConstants.COMMENT_MENU,
   data: model,
});

const searchMenu = (model) => ({
   type: menuConstants.SEARCH_MENU,
   data: model,
});

export const menuActions = {
   getAllMenus,
   getPopularMenus,
   getCategoryMenu,
   getAMenu,
   searchMenu,
   commentMenu,
};
