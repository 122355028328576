import {combineReducers} from 'redux';
import {appConstants, orderConstants} from '../../_constants';
import {updateObject} from '../../_helpers';

const IsCreatingOrder = (state = false, action) => {
   switch (action.type) {
      case orderConstants.REQUEST_CREATE_ORDER:
         return true;
      case orderConstants.CREATE_ORDER_SUCCESS:
         return false;
      case orderConstants.CREATE_ORDER_ERROR:
         return false;
      default:
         return state;
   }
};

const orderCreatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case orderConstants.REQUEST_CREATE_ORDER:
         return false;
      case orderConstants.CREATE_ORDER_SUCCESS:
         return true;
      case orderConstants.CREATE_ORDER_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const orderDetails = (state = null, {type, order}) => {
   switch (type) {
      case orderConstants.CREATE_ORDER_SUCCESS:
         return updateObject(state, order.data);
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsCreatingOrder,
   orderCreatedSuccessfully,
   orderDetails,
});

export default rootReducer;
