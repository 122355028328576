import React, {useState} from 'react';
import {Reservations} from '../../../../_components';
import './index.scss';

import home5 from '../../../../_assets/images/home55.jpg';
import {ReactComponent as ArrowLeftIcon} from '../../../../_assets/icons/bigArrowLeft.svg';

const Section5 = () => {
   const [modalOpen, setModalOpen] = useState(false);

   return (
      <section className="sectionFive container-fluid p-0 position-relative overflow-hidden">
         <div className="content container px-md-0 py-md-5 py-3 my-md-4">
            <div className="row m-0 banner">
               <div className="position-relative order-2 order-md-1 reserve py-md-0 py-4 col-md-6 col-12 d-flex flex-column justify-content-center">
                  <p data-aos="fade-up" className="title">
                     Do You Have any Meal Plan Today? Reserve Your Table.
                  </p>
                  <p data-aos="fade-up" className="subTitle py-md-3 py-4">
                     Make online reservations. We delight guests with our menu and thrill their senses with an added
                     culinary artistry.
                  </p>
                  <div className="d-inline-flex mt-md-3 mt-3">
                     <button
                        data-aos="fade-up"
                        // data-aos-duration="750"
                        onClick={() => setModalOpen(true)}
                        className="reservations text-capitalize">
                        make reservations
                     </button>
                     <Reservations modalOpen={modalOpen} setModalOpen={setModalOpen} />
                  </div>
                  <div
                     data-aos="fade-up"
                     // data-aos-duration="1000"
                     className="iconWrapper position-absolute"
                     style={{zIndex: '2'}}>
                     <ArrowLeftIcon className="icon" />
                  </div>
               </div>
               <div className="col-md-6 col-12 order-1 order-md-2 py-md-0 py-3 pt-md-0 pt-4">
                  <div data-aos="zoom-in" className="img">
                     <img src={home5} alt="" className="h-100 w-100" />
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Section5;
