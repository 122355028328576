import {appConstants} from './app.constant';

const blog = 'post';

export const blogConstants = {
   REQUEST_ALL_BLOGS: 'REQUEST_ALL_BLOGS',
   REQUEST_ALL_BLOGS_SUCCESS: 'REQUEST_ALL_BLOGS_SUCCESS',
   REQUEST_ALL_BLOGS_SUCCESS_WITHOUT_DATA: 'REQUEST_ALL_BLOGS_SUCCESS_WITHOUT_DATA',
   REQUEST_ALL_BLOGS_ERROR: 'REQUEST_ALL_BLOGS_ERROR',

   REQUEST_RECENT_BLOGS: 'REQUEST_RECENT_BLOGS',
   REQUEST_RECENT_BLOGS_SUCCESS: 'REQUEST_RECENT_BLOGS_SUCCESS',
   REQUEST_RECENT_BLOGS_ERROR: 'REQUEST_RECENT_BLOGS_ERROR',

   REQUEST_A_BLOG: 'REQUEST_A_BLOG',
   REQUEST_A_BLOG_SUCCESS: 'REQUEST_A_BLOG_SUCCESS',
   REQUEST_A_BLOG_ERROR: 'REQUEST_A_BLOG_ERROR',

   REQUEST_COMMENT_BLOG: 'REQUEST_COMMENT_BLOG',
   REQUEST_COMMENT_BLOG_SUCCESS: 'REQUEST_COMMENT_BLOG_SUCCESS',
   REQUEST_COMMENT_BLOG_ERROR: 'REQUEST_COMMENT_BLOG_ERROR',

   GET_ALL_BLOGS: 'GET_ALL_BLOGS',
   GET_RECENT_BLOGS: 'GET_RECENT_BLOGS',
   GET_A_BLOG: 'GET_A_BLOG',
   COMMENT_BLOG: 'COMMENT_BLOG',

   BLOG_URI: `${appConstants.BASE_URI}${blog}`,
};
