import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import StarRating from 'react-svg-star-rating';
import Comments from './Comments';
import {RelatedDishes} from '../../_components';
// import {comments} from '../../_constants';
import {cartActions, menuActions} from '../../_actions';
import './index.scss';
import {numberWithCommas, capitalizeFirstLetter} from '../../_helpers';

import logoImg from '../../_assets/images/newLogo.png';
import {ReactComponent as CommentIcon} from '../../_assets/icons/comments.svg';

// const tags = [{tag: 'Cooking'}, {tag: 'Breakfast'}, {tag: 'French Food'}];

const ViewItem = () => {
   const {state} = useLocation();
   const dish = state?.dish;

   const dispatch = useDispatch();
   const {categoryMenusList, menuDetails} = useSelector((s) => s.menu);
   const cartList = useSelector((s) => s.cart.cartList);

   const relatedMenus = categoryMenusList.filter((menu) => menu._id !== dish?._id);
   const [count, setCount] = useState(1);
   // const [starRating, setStarRating] = useState(4.5);
   const [addedToCart, setAddedToCart] = useState(false);
   console.log('menuDetails', menuDetails);

   useEffect(() => {
      dispatch(menuActions.getAMenu({_id: dish?._id}));

      const inCart = cartList?.filter((item) => item?._id === dish?._id);
      setAddedToCart(inCart?.length > 0 ? true : false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dish]);

   // const handleStarClick = (rating) => {
   //    setStarRating(rating);
   // };

   const handleIncrease = useCallback(() => {
      setCount(count + 1);
   }, [count]);

   const handleDecrease = useCallback(() => {
      if (count <= 0) return;
      setCount(count - 1);
   }, [count]);

   const handleAddToCart = (item) => {
      item['quantity'] = count;
      item['amount'] = item.price * count;

      dispatch(cartActions.addToCart(dish));
      setAddedToCart((t) => !t);

      // dispatch(
      //    appActions.setSnackBar({
      //       message: `${dish.title} has been added to your cart!`,
      //       variant: 'success',
      //       img: dish.img,
      //    }),
      // );
      // eslint-disable-next-line react-hooks/exhaustive-deps
   };

   return (
      <section className="viewItem container-fluid p-0 overflow-hidden">
         <div className="content container py-lg-5 py-2 mb-5">
            <div className="row m-0 py-3">
               <div className="col-md-6 col-12 ps-lg-5 pe-lg-5 mb-md-0 mb-5">
                  <div
                     data-aos="fade-up"
                     data-aos-duration="500"
                     className="img position-relative d-flex align-items-center justify-content-center">
                     {dish.img_url ? (
                        <img src={dish.img_url} alt="" className="h-100 w-100" />
                     ) : (
                        <img src={logoImg} alt="" className="noImg" />
                     )}
                     <div data-aos="fade-up" data-aos-duration="750" className="label p-lg-4 p-3">
                        <h4 className="pb-lg-3 pb-1">{dish?.name}</h4>
                        <p className="">&#8358; {numberWithCommas(dish.price)}</p>
                     </div>
                  </div>
               </div>

               <div className="col-md-6 col-12 px-lg-5">
                  <div data-aos="fade-up" data-aos-duration="500" className="description p-4">
                     <div className="d-flex flex-wrap align-items-center justify-content-between">
                        <div className="rating d-flex align-items-center">
                           <p className="me-1 rate">{dish.totalRatings > 0 ? dish.totalRatings : 'No'}</p> Rating
                           <span className="ms-1">
                              <StarRating
                                 unit="half"
                                 size={28}
                                 count={5}
                                 initialRating={dish.totalRatings}
                                 activeColor="#FFC403"
                                 hoverColor="#FFC403"
                                 emptyColor="rgba(0, 0, 0, .3)"
                                 roundedCorner={true}
                                 // handleOnClick={handleStarClick}
                                 isReadOnly={true}
                                 innerRadius={20}
                                 outerRadius={45}
                                 starClassName="px-1 each-star"
                                 containerClassName=""
                              />
                           </span>
                        </div>
                        <p className="commentsCount d-flex align-items-center">
                           <CommentIcon style={{transform: 'scale(.7)'}} />{' '}
                           <span className="ps-1">
                              {menuDetails?.comments?.length || dish?.comments?.length}{' '}
                              {menuDetails?.comments?.length || dish?.comments?.length === 1 ? 'Comment' : 'Comments'}
                           </span>
                        </p>
                     </div>
                     <hr className="my-lg-3 my-2" />
                     <p className="descLabel">Description</p>
                     <p className="desc pb-4">{capitalizeFirstLetter(dish?.description)}</p>
                     <hr className="my-lg-3 my-2" />
                     {dish?.tags?.length > 0 && (
                        <div className="d-flex flex-wrap align-items-center">
                           <span className="tags">Tags:</span>
                           {dish?.tags?.map((item, index) => (
                              <span key={`tags-${index}`} className="tag mx-md-3 mx-1">
                                 {capitalizeFirstLetter(item.name)}
                              </span>
                           ))}
                        </div>
                     )}
                     <hr className="my-lg-3 my-2" />
                     <div className="quantity my-3 d-flex align-items-center">
                        <button onClick={handleDecrease} className="minus pb-1">
                           -
                        </button>
                        <span className="numb mx-4 d-flex align-items-center justify-content-center">
                           {count <= 9 ? `0${count}` : count}
                        </span>
                        <button onClick={handleIncrease} className="add pb-1">
                           +
                        </button>
                     </div>
                     <button
                        onClick={() => handleAddToCart(dish)}
                        className="addToCart mt-lg-5 mt-4 w-100 d-flex align-items-center justify-content-center">
                        {addedToCart ? 'Added to Cart' : 'Add to Cart'}
                     </button>
                  </div>
               </div>
            </div>
            <Comments dish={menuDetails || dish} />
         </div>
         {relatedMenus?.length > 1 && <RelatedDishes data={relatedMenus} />}
      </section>
   );
};

export default ViewItem;
