import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import './index.scss';

import {Section1, Section2, Section3, Section4, Section5, Section6} from './_components';
import {menuActions, blogActions} from '../../_actions';

const Home = () => {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(blogActions.getAllBlogs());
      dispatch(menuActions.getPopularMenus());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="__home">
         <Section1 />
         <Section2 />
         <Section3 />
         {/* <Section4 /> */}
         <Section5 />
         <Section6 />
      </div>
   );
};

export default Home;
