import {range} from 'lodash/fp';
import localForage from 'localforage';

export * from './reducerUtility';
// export * from './hookes';
export * from './hooks';
// export * from './routes';
// export * from './countries';

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export const rounded = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

export const getObjectFromStorage = async (key) => {
   try {
      const object = await localForage.getItem(key);

      if (!object) {
         return null;
      }

      return object;
   } catch (error) {
      throw error;
   }
};

export const getObjectFromStorageWithExpiry = async (key) => {
   try {
      const object = await localForage.getItem(key);

      if (!object) {
         return null;
      }

      const item = JSON.parse(object);
      const now = new Date();
      //return object
      if (now.getTime() > item.expiry) {
         // If the item is expired, delete the item from storage
         // and return null
         localStorage.removeItem(key);
         return null;
      }
      return item.value;
   } catch (error) {
      throw error;
   }
};

export const clearObjectFromStorage = async (key) => {
   try {
      await localForage.removeItem(key);
      return true;
   } catch (error) {
      throw error;
   }
};

export const setObjectInStorage = async (key, object) => {
   try {
      await localForage.setItem(key, object);
      return true;
   } catch (error) {
      throw error;
   }
};

export const setObjectInStorageWithExpiry = async (key, object, ttl) => {
   try {
      const now = new Date();
      const item = {
         value: object,
         expiry: now.getTime() + ttl,
      };
      await localForage.setItem(key, JSON.stringify(item));
      return true;
   } catch (error) {
      throw error;
   }
};

export const checkStatus = (response) => {
   if (response.status >= 200 && response.status < 300) {
      return response;
   } else {
      const error = new Error(`HTTP Error ${response.statusText}`);
      error.status = response.statusText;
      error.response = response;

      throw error;
   }
};

export const checkContentType = (contentType) => (response) => {
   const responseContentType = response.headers.get('content-type');
   if (responseContentType && responseContentType.includes(contentType)) {
      return response;
   }
   return null;
};

export const parseJSON = (response) => response.json();

export const createRequestWithToken =
   (url = '', config) =>
   (token) => {
      const validMethods = ['GET', 'POST', 'HEAD', 'PUT', 'DELETE', 'PATCH'];
      const defaultconfig = {
         mode: 'cors',
         cache: 'default',
         credentials: 'same-origin',
      };
      const defaultHeaders = new Headers();

      defaultHeaders.set('Content-Type', 'application/json');
      defaultHeaders.set('Authorization', `Bearer ${token}`);
      defaultHeaders.set('Accept', 'application/json');

      if (typeof config.method !== 'string') {
         throw new TypeError('config method property must be a string.');
      }
      if (validMethods.indexOf(config.method.toUpperCase()) === -1) {
         throw Error("config method property value most be one of ['GET','POST','HEAD','PUT','DELETE']");
      }

      config.headers = config.headers || defaultHeaders;

      if (config.headers && !config.headers instanceof Headers) {
         throw new TypeError('config headers property must be of type Headers.');
      }

      const requestConfig = {
         ...defaultconfig,
         ...config,
      };
      return new Request(url, requestConfig);
   };

export const createMultiPartRequestWithToken =
   (url = '', config) =>
   (token) => {
      const validMethods = ['GET', 'POST', 'HEAD', 'PUT', 'DELETE', 'PATCH'];
      const defaultconfig = {
         mode: 'cors',
         cache: 'default',
         credentials: 'same-origin',
      };
      const defaultHeaders = new Headers();
      defaultHeaders.set('Authorization', `Bearer ${token}`);
      defaultHeaders.set('Accept', `application/json`);

      if (typeof config.method !== 'string') {
         throw new TypeError('config method property must be a string.');
      }
      if (validMethods.indexOf(config.method.toUpperCase()) === -1) {
         throw Error("config method property value most be one of ['GET','POST','HEAD','PUT','DELETE']");
      }

      config.headers = config.headers || defaultHeaders;

      if (config.headers && !config.headers instanceof Headers) {
         throw new TypeError('config headers property must be of type Headers.');
      }

      const requestConfig = {
         ...defaultconfig,
         ...config,
      };
      return new Request(url, requestConfig);
   };

export const createRequest = (url = '', config, token = '') => {
   const validMethods = ['GET', 'POST', 'HEAD', 'PUT', 'DELETE', 'PATCH'];
   const defaultconfig = {
      mode: 'cors',
      cache: 'default',
      credentials: 'same-origin',
   };
   const defaultHeaders = new Headers();
   defaultHeaders.set('Content-Type', 'application/json');
   // defaultHeaders.set('Authorization', `Bearer ${token}`);
   defaultHeaders.set('Accept', `application/json`);

   if (typeof config.method !== 'string') {
      throw new TypeError('config method property must be a string.');
   }
   if (validMethods.indexOf(config.method.toUpperCase()) === -1) {
      throw Error("config method property value most be one of ['GET','POST','HEAD','PUT','DELETE']");
   }

   config.headers = config.headers || defaultHeaders;

   if (config.headers && !config.headers instanceof Headers) {
      throw new TypeError('config headers property must be of type Headers.');
   }

   const requestConfig = {
      ...defaultconfig,
      ...config,
   };
   return new Request(url, requestConfig);
};

export const is_Array = (value) => value && typeof value === 'object' && value.constructor === Array;

export const createPager = ({totalCount: totalItems, pageSize}, currentPage) => {
   if (!totalItems) {
      return false;
   }
   const totalPages = Math.ceil(totalItems / pageSize);
   let startPage, endPage;
   if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
   } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
         startPage = 1;
         endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
         startPage = totalPages - 9;
         endPage = totalPages;
      } else {
         startPage = currentPage - 5;
         endPage = currentPage + 4;
      }
   }
   // calculate start and end item indexes
   const startIndex = (currentPage - 1) * pageSize;
   const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

   // create an array of pages to ng-repeat in the pager control
   const pages = range(startPage, endPage + 1);

   // return object with all pager properties required by the view
   return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
   };
};

export const removeTrailingSlash = (str) => {
   return str.split('')[str.length - 1] === '/'
      ? str
           .split('')
           .slice(0, str.length - 1)
           .join('')
      : str;
};

export const capitalizeFirstLetter = (stringx) => {
   let string = stringx?.toLowerCase();
   string = string?.split(' ');

   for (var i = 0; i < string?.length; i++) {
      string[i] = string[i]?.charAt(0).toUpperCase() + string[i]?.slice(1);
   }

   return string?.join(' ');
};

export const capitalizeFirstWord = (str) => {
   // converting first letter to uppercase
   const capitalized = str?.charAt(0)?.toUpperCase() + str?.slice(1);

   return capitalized;
};

export const getKey = (obj, value) => {
   return Object.keys(obj).find((key) => obj[key] === value);
};

export const getValue = (obj, value) => {
   return Object.values(obj).find((key) => obj[key] === value);
};

export const isANumber = (str) => {
   return !/\D/.test(str);
};

export const transformToFormData = (data) => {
   var fData = new FormData();
   // console.log('mydata', data);
   fData.append('cv_filename', data.cv_file);
   fData.append('other_file_filename', data.other_file);
   fData.append('firstname', data.firstname);
   fData.append('lastname', data.lastname);
   fData.append('email', data.email);
   fData.append('phone_number', data.phone_number);
   fData.append('linkedInProfile', data.linkedInProfile);
   fData.append('job_advert_uuid', data.job_advert_uuid);

   if (fData != null) {
      for (var value of fData) {
         // console.log(value);
      }
   }

   return fData;
};

export const toFormData = (data) => {
   const fData = new FormData();
   Object.keys(data).forEach((v) => fData.append(v, data[v]));
   return fData;
};

export const sortJobAdverts = (array) => {
   array.sort(function (a, b) {
      a = new Date(a.created_at);
      b = new Date(b.created_at);
      return a > b ? -1 : a < b ? 1 : 0;
   });
};

/* export const DateToString = (inDate, formatString) => {
    var z = {
        M: inDate.getMonth() + 1,
        d: inDate.getDate(),
        h: inDate.getHours(),
        m: inDate.getMinutes(),
        s: inDate.getSeconds()
    };
    formatString = formatString.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
        return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
    });

    return formatString.replace(/(y+)/g, function(v) {
        return inDate.getFullYear().toString().slice(-v.length)
    });
} */

export const readableDateString = (dateString) => {
   if (isDate(dateString)) {
      var date = new Date(dateString);

      return date.toDateString();
   }

   return 'no date';
};

export const timeConvert = (num) => {
   var hours = Math.floor(num / 60);
   var minutes = num % 60;
   return hours + ':' + minutes;
};

export const convertTime = (time) => {
   return `${padZero(parseInt((time / (60 * 60)) % 24))}:${padZero(parseInt((time / 60) % 60))}:${padZero(
      parseInt(time % 60),
   )}`;
};

const padZero = (v) => {
   return v < 10 ? '0' + v : v;
};

export const isDate = (sDate) => {
   if (sDate.toString() === parseInt(sDate).toString()) return false;
   var tryDate = new Date(sDate);
   return tryDate && tryDate.toString() !== 'NaN' && tryDate !== 'Invalid Date';
};

export const dateDifference = (fromDate) => {
   var date2 = new Date();
   var date1 = new Date(fromDate);
   var timeDiff = Math.abs(date2.getTime() - date1.getTime());

   return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

export const generateString = (length) => {
   var result = '';
   var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;

   for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }

   return result;
};

// Slugify a string
export const slugify = (str) => {
   str = str.replace(/^\s+|\s+$/g, '');

   // Make the string lowercase
   str = str.toLowerCase();

   // Remove accents, swap ñ for n, etc
   var from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;';
   var to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------';
   for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
   }

   // Remove invalid chars
   str = str
      .replace(/[^a-z0-9 -]/g, '')
      // Collapse whitespace and replace by -
      .replace(/\s+/g, '-')
      // Collapse dashes
      .replace(/-+/g, '-');

   return str;
};

export function numberWithCommas(x) {
   if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   }
   return 0;
}

export const isEmptyObject = (obj) => {
   for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
   }
   return true;
};

export const DateToString = (inDate, formatString) => {
   var z = {
      M: inDate.getMonth() + 1,
      d: inDate.getDate(),
      h: inDate.getHours(),
      m: inDate.getMinutes(),
      s: inDate.getSeconds(),
   };
   formatString = formatString.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? '0' : '') + eval('z.' + v.slice(-1))).slice(-2);
   });

   return formatString.replace(/(y+)/g, function (v) {
      return inDate.getFullYear().toString().slice(-v.length);
   });
};

export const GetTime = (date) => {
   var currentTime = new Date(date);
   var hours = currentTime.getHours();
   //Note: before converting into 12 hour format
   var suffix = '';
   if (hours > 11) {
      suffix += 'PM';
   } else {
      suffix += 'AM';
   }
   var minutes = currentTime.getMinutes();
   if (minutes < 10) {
      minutes = '0' + minutes;
   }
   if (hours > 12) {
      hours -= 12;
   } else if (hours === 0) {
      hours = 12;
   }
   var time = hours + ':' + minutes + ' ' + suffix;

   return time;
};

export function formatMilliseconds(time) {
   var hours = Math.floor(time / 3600);
   var minutes = ('0' + Math.floor((time / 60) % 60)).slice(-2);
   var seconds = ('0' + Math.floor(time % 60)).slice(-2);

   return hours + ':' + minutes + ':' + seconds;
}

export function timeSince(date) {
   var seconds = Math.floor((new Date() - new Date(date)) / 1000);

   var interval = seconds / 31536000;

   if (interval > 1) {
      if (Math.floor(interval) > 1) {
         return Math.floor(interval) + ' years ago';
      } else {
         return Math.floor(interval) + ' year ago';
      }
   }
   interval = seconds / 2592000;

   if (interval > 1) {
      if (Math.floor(interval) > 1) {
         return Math.floor(interval) + ' months ago';
      } else {
         return Math.floor(interval) + ' month ago';
      }
   }
   interval = seconds / 604800;
   if (interval > 1) {
      if (Math.floor(interval) > 1) {
         return Math.floor(interval) + ' weeks ago';
      } else {
         return Math.floor(interval) + ' week ago';
      }
   }
   interval = seconds / 86400;
   if (interval > 1) {
      if (Math.floor(interval) > 1) {
         return Math.floor(interval) + ' days ago';
      } else {
         return Math.floor(interval) + ' day ago';
      }
   }
   interval = seconds / 3600;
   if (interval > 1) {
      if (Math.floor(interval) > 1) {
         return Math.floor(interval) + ' hours ago';
      } else {
         return Math.floor(interval) + ' hour ago';
      }
   }
   interval = seconds / 60;
   if (interval > 1) {
      if (Math.floor(interval) > 1) {
         return Math.floor(interval) + ' minutes ago';
      } else {
         return Math.floor(interval) + ' minute ago';
      }
   }
   return Math.floor(seconds) + ' seconds ago';
}

// export function timeSince(previous) {
//    var current = new Date();
//    console.log('current', current)
//    console.log('previous', previous)

//    var msPerMinute = 60 * 1000;
//    var msPerHour = msPerMinute * 60;
//    var msPerDay = msPerHour * 24;
//    var msPerMonth = msPerDay * 30;
//    var msPerYear = msPerDay * 365;

//    var elapsed = current - new Date(previous);
//    console.log('elapsed', elapsed)

//    if (elapsed < msPerMinute) {
//       return Math.round(elapsed / 1000) + ' seconds ago';
//    } else if (elapsed < msPerHour) {
//       return Math.round(elapsed / msPerMinute) + ' minutes ago';
//    } else if (elapsed < msPerDay) {
//       return Math.round(elapsed / msPerHour) + ' hours ago';
//    } else if (elapsed < msPerMonth) {
//       return Math.round(elapsed / msPerDay) + ' days ago';
//    } else if (elapsed < msPerYear) {
//       return Math.round(elapsed / msPerMonth) + ' months ago';
//    } else {
//       return Math.round(elapsed / msPerYear) + ' years ago';
//    }
// }
//export const Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/++[++^A-Za-z0-9+/=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}
