import {combineReducers} from 'redux';
import {reservationConstants, appConstants} from '../../_constants';
import {updateObject} from '../../_helpers';

const IsCreatingReservation = (state = false, action) => {
   switch (action.type) {
      case reservationConstants.REQUEST_CREATE_RESERVATION:
         return true;
      case reservationConstants.CREATE_RESERVATION_SUCCESS:
         return false;
      case reservationConstants.CREATE_RESERVATION_ERROR:
         return false;
      default:
         return state;
   }
};

const reservationCreatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case reservationConstants.REQUEST_CREATE_RESERVATION:
         return false;
      case reservationConstants.CREATE_RESERVATION_SUCCESS:
         return true;
      case reservationConstants.CREATE_RESERVATION_ERROR:
         return false;
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case reservationConstants.CREATE_RESERVATION_ERROR:
         return updateObject(state, error);
      case reservationConstants.REQUEST_CREATE_RESERVATION:
         return null;
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsCreatingReservation,
   reservationCreatedSuccessfully,
   errorMessage,
});

export default rootReducer;
