import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appConstants, setupConstants} from '../../_constants';
import {checkStatus, createRequest, setObjectInStorage} from '../../_helpers';

function* getSetupDataSaga({data}) {
   yield put({type: setupConstants.REQUEST_SETUP_DATA});
   try {
      const categoriesReq = yield call(createRequest, `${appConstants.CATEGORY_URL}`, {method: 'GET'}, '');
      // const banksReq = yield call(createRequest, `${appConstants.BANK_URL}?limit=1000`, {method: 'GET'}, '');

      const responses = yield all([call(fetch, categoriesReq)]);
      const [categoriesRes] = responses;
      yield all([call(checkStatus, categoriesRes)]);
      const jsonResponses = yield all([call(categoriesRes.json.bind(categoriesRes))]);
      const [categories] = jsonResponses;

      yield call(setObjectInStorage, appConstants.CATEGORY_KEY, categories);
      // yield call(setObjectInStorage, appConstants.BANK_KEY, banks);

      yield put({
         type: setupConstants.REQUEST_SETUP_DATA_SUCCESS,
         data: {
            categories,
            // banks,
         },
      });
   } catch (error) {
      yield put({type: setupConstants.REQUEST_SETUP_DATA_ERROR});
   }
}
function* getSetupDataSagaWatcher() {
   yield takeLatest('SETUP_APP', getSetupDataSaga);
}
export default function* rootSaga() {
   yield all([getSetupDataSagaWatcher()]);
}
