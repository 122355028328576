/* eslint-disable eqeqeq */
import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {cartConstants} from '../../_constants';
import {setObjectInStorage, clearObjectFromStorage, getObjectFromStorage} from '../../_helpers';

function* getAllCartItemsSaga() {
   try {
      yield put({type: cartConstants.REQUEST_ALL_CART_ITEMS});
      const cartItems = yield call(getObjectFromStorage, cartConstants.CART_KEY);
      if (cartItems) {
         yield put({
            type: cartConstants.REQUEST_ALL_CART_ITEMS_SUCCESS,
            cartItems,
         });
         return;
      }

      yield call(clearObjectFromStorage, cartConstants.CART_KEY);
      yield put({type: cartConstants.REQUEST_ALL_CART_ITEMS_ERROR});
   } catch (error) {
      yield put({type: cartConstants.REQUEST_ALL_CART_ITEMS_ERROR});
   }
}

function* addToCartSaga({data}) {
   try {
      yield put({
         type: cartConstants.REQUEST_ADD_TO_CART,
      });
      const cartItems = yield call(getObjectFromStorage, cartConstants.CART_KEY);

      if (!cartItems) {
         const newList = [data];
         yield call(setObjectInStorage, cartConstants.CART_KEY, newList);
         yield put({
            type: cartConstants.REQUEST_ADD_TO_CART_SUCCESS,
            newList,
         });
      } else {
         if (cartItems.filter((item) => item._id !== data._id)) {
            const newList = [data, ...cartItems];
            yield call(setObjectInStorage, cartConstants.CART_KEY, newList);
            yield put({
               type: cartConstants.REQUEST_ADD_TO_CART_SUCCESS,
               newList,
            });
         } else {
            const remainingcartItems = cartItems.filter((item) => item._id !== data._id);
            const newList = [data, ...remainingcartItems];
            yield call(setObjectInStorage, cartConstants.CART_KEY, newList);
            yield put({
               type: cartConstants.REQUEST_ADD_TO_CART_SUCCESS,
               newList,
            });
         }
      }
      yield put(
         appActions.setSnackBar({
            message: `${data?.name} successfully added to cart`,
            variant: 'success',
            img: data?.img_url,
         }),
      );
   } catch (error) {
      yield put({type: cartConstants.REQUEST_ADD_TO_CART_ERROR});
   }
}

function* updateItemQuantitySaga({data}) {
   try {
      yield put({
         type: cartConstants.REQUEST_UPDATE_ITEM_QUANTITY_IN_CART,
      });
      const recentItems = yield call(getObjectFromStorage, cartConstants.CART_KEY);

      if (recentItems) {
         const newList = recentItems.map((itm) => {
            if (itm._id === data._id) {
               return {
                  ...data,
               };
            }

            return itm;
         });
         yield call(setObjectInStorage, cartConstants.CART_KEY, newList);
         yield put({
            type: cartConstants.REQUEST_UPDATE_ITEM_QUANTITY_IN_CART_SUCCESS,
            newList,
         });
         yield put(
            appActions.setSnackBar({
               message: `${data?.name} successfully updated to cart`,
               variant: 'success',
               img: data?.img_url,
            }),
         );
      }
   } catch (error) {
      yield put({type: cartConstants.REQUEST_UPDATE_ITEM_QUANTITY_IN_CART_ERROR});
   }
}

function* removeFromCartSaga({data}) {
   try {
      yield put({type: cartConstants.REQUEST_REMOVE_FROM_CART});
      const cartItems = yield call(getObjectFromStorage, cartConstants.CART_KEY);

      if (cartItems) {
         const remainingcartItems = cartItems.filter((item) => item._id !== data._id) ?? [];
         const newList = [...remainingcartItems];
         yield call(setObjectInStorage, cartConstants.CART_KEY, newList);
         yield put({
            type: cartConstants.REQUEST_REMOVE_FROM_CART_SUCCESS,
            newList,
         });
         yield put(
            appActions.setSnackBar({
               Item: 'Item deleted from cart',
               success: true,
               message: `Item deleted from cart`,
               variant: 'success',
               img: data?.img_url,
            }),
         );
      }
   } catch (error) {
      yield put({type: cartConstants.REQUEST_REMOVE_FROM_CART_ERROR});
   }
}

function* clearCartSaga() {
   try {
      yield put({type: cartConstants.REQUEST_CLEAR_CART});
      const cartItems = yield call(getObjectFromStorage, cartConstants.CART_KEY);

      if (cartItems) {
         yield call(setObjectInStorage, cartConstants.CART_KEY, []);
         yield put({
            type: cartConstants.REQUEST_CLEAR_CART_SUCCESS,
            newList: [],
         });
         yield put(
            appActions.setSnackBar({
               Item: 'Cart cleared',
               success: true,
            }),
         );
      }
   } catch (error) {
      yield put({type: cartConstants.REQUEST_CLEAR_CART_ERROR});
   }
}

function* getAllCartItemsSagaWatcher() {
   yield takeLatest(cartConstants.GET_ALL_CART_ITEMS, getAllCartItemsSaga);
}

function* addToCartSagaWatcher() {
   yield takeLatest(cartConstants.ADD_TO_CART, addToCartSaga);
}

function* updateItemQuantitySagaWatcher() {
   yield takeLatest(cartConstants.UPDATE_ITEM_QUANTITY_IN_CART, updateItemQuantitySaga);
}

function* removeFromCartSagaWatcher() {
   yield takeLatest(cartConstants.REMOVE_FROM_CART, removeFromCartSaga);
}

function* clearCartSagaWatcher() {
   yield takeLatest(cartConstants.CLEAR_CART, clearCartSaga);
}

export default function* rootSaga() {
   yield all([
      getAllCartItemsSagaWatcher(),
      addToCartSagaWatcher(),
      updateItemQuantitySagaWatcher(),
      removeFromCartSagaWatcher(),
      clearCartSagaWatcher(),
   ]);
}
