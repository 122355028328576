import {blogConstants} from '../_constants';

const getAllBlogs = (model) => ({
   type: blogConstants.GET_ALL_BLOGS,
   data: model,
});

const getRecentBlogs = () => ({
   type: blogConstants.GET_RECENT_BLOGS,
});

const getABlog = (model) => ({
   type: blogConstants.GET_A_BLOG,
   data: model,
});

const commentBlog = (model) => ({
   type: blogConstants.COMMENT_BLOG,
   data: model,
});

export const blogActions = {
   getAllBlogs,
   getRecentBlogs,
   getABlog,
   commentBlog,
};
