import {matchPath, useLocation} from 'react-router-dom';

export const useLandingPageRoute = () => {
   const {pathname} = useLocation();

   const home = matchPath('/', pathname);
   const about = matchPath('/about', pathname);
   const menu = matchPath('/menu', pathname);
   const viewItem = matchPath('/view-item/*', pathname);
   const blog = matchPath('/blog', pathname);
   const viewBlog = matchPath('/blog/:uuid', pathname);
   const contact = matchPath('/contact', pathname);
   const cart = matchPath('/cart', pathname);

   // const login = matchPath('/login', pathname);
   // const signup = matchPath('/signup', pathname);
   // const forgot = matchPath('/forgot-password', pathname);
   // const verification = matchPath('/verification', pathname);
   // const verifying = matchPath('/users/:email/verify/:token', pathname);

   if (home) {
      return 1;
   } else if (about) {
      return 2;
   } else if (menu || viewItem) {
      return 3;
   } else if (blog || viewBlog) {
      return 4;
   } else if (contact) {
      return 5;
   } else if (cart) {
      return 6;
   } else {
      return 7;
   }
};

export const useAdminRoute = () => {
   const {pathname} = useLocation();

   const routes = [
      '/admin/dashboard',
      '/admin/signup',
      '/admin/login',
      '/admin/set-password',
      '/admin/users/:email/verify/:token',
      '/admin/user/reset/token/:token',
   ];

   const checker = (route) => routes.find((item) => matchPath(route, item));

   return checker(pathname);
};

export const useDashboardRoute = () => {
   const {pathname} = useLocation();
   const dashboard = matchPath('/dashboard', pathname);
   const message = matchPath('/message', pathname);
   const product = matchPath('/product', pathname);
   const addproduct = matchPath('/product/add-product', pathname);
   const profile = matchPath('/profile', pathname);
   const transaction = matchPath('/transaction', pathname);
   const dispute = matchPath('/dispute', pathname);
   const alert = matchPath('/alert', pathname);
   const contacts = matchPath('/contacts', pathname);

   if (dashboard) {
      return 1;
   } else if (message) {
      return 2;
   } else if (product || addproduct) {
      return 3;
   } else if (profile) {
      return 4;
   } else if (transaction) {
      return 5;
   } else if (dispute) {
      return 6;
   } else if (alert) {
      return 7;
   } else if (contacts) {
      return 8;
   } else {
      return 9;
   }
};

export const useAuthRoute = () => {
   const {pathname} = useLocation();

   const routes = [
      '/d/signup',
      '/d/login',
      '/d/set-password',
      '/users/:email/verify/:token',
      '/user/reset/token/:token',
   ];

   const checker = (route) => routes.indexOf(route) !== -1;

   return checker(pathname);
};

export const useMatch = (path) => {
   const {pathname} = useLocation();
   return matchPath(pathname, path);
};
