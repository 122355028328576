import React from 'react';
import './index.scss';
import {ReactComponent as Prev} from '../../_assets/icons/arrowLeft.svg';
import {ReactComponent as Next} from '../../_assets/icons/arrowRight.svg';

const Pagination = (props) => {
   const {page, totalPages} = props;

   const handleBackButtonClick = (event) => {
      if (page !== 0) {
         props.onChangePage(event, page - 1);
      }
   };

   const handleNextButtonClick = (event) => {
      if (page + 1 !== totalPages) {
         props.onChangePage(event, page + 1);
      }
   };

   const handlePageClick = (i) => {
      props.onChangePage(i, Number(i.target.innerHTML) - 1);
   };

   const buttons = [];
   const button = [];
   const pages = page + 5;

   for (var i = 0; i < totalPages; i++) {
      buttons.push(
         <li className="mx-3 d-flex align-items-center">
            <p onClick={(i) => handlePageClick(i)} className={page === i ? 'active' : ''}>
               {i + 1}
            </p>
         </li>,
      );
   }

   for (var j = page; j < pages; j++) {
      button.push(
         <li className="mx-3 d-flex align-items-center">
            <p onClick={(j) => handlePageClick(j)} className={page === j ? 'active' : ''}>
               {j + 1}
            </p>
         </li>,
      );
   }
   return (
      <div className="my-4">
         <ul className="pagination d-flex align-items-center">
            <li onClick={handleBackButtonClick} disabled={page === 0} className="mx-3 d-flex align-items-center">
               <Prev className="icon me-2" cursor="pointer" />
               Prev.
            </li>
            {totalPages > 5 ? button : buttons}

            {/* <li className="mx-3">
               <p className="">1</p>
            </li>
            <li className="mx-3">
               <p>2</p>
            </li>
            <li className="mx-3">
               <p>3</p>
            </li>
            <li className="mx-3">
               <p>4</p>
            </li> */}
            <li
               onClick={handleNextButtonClick}
               disabled={page + 1 === totalPages}
               className="mx-3 d-flex align-items-center">
               Next
               <Next className="icon ms-2" cursor="pointer" />
            </li>
         </ul>
      </div>
   );
};

export default Pagination;
