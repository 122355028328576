import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {cartActions} from '../../_actions';
import {numberWithCommas} from '../../_helpers';
import './index.scss';

import logoImg from '../../_assets/images/newLogo.png';

const DishCards = ({className, data, meal, img_url, name, description, price}) => {
   // console.log('data ===========> ', data);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const cartList = useSelector((s) => s.cart.cartList);
   const [addedToCart, setAddedToCart] = useState(false);

   useEffect(() => {
      const inCart = cartList?.filter((item) => item?._id === data?._id);
      setAddedToCart(inCart?.length > 0 ? true : false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data]);

   const handleViewItem = (item) => {
      navigate(`/view-item/${item?.meal?._id || item?._id}`, {state: {dish: item?.meal || item}});
   };

   const handleAddToCart = (item) => {
      if (addedToCart) {
         dispatch(cartActions.removeFromCart({_id: item?._id}));
      } else {
         item['quantity'] = 1;
         item['amount'] = item.price;
         if (!meal) {
            dispatch(cartActions.addToCart(item));
         } else {
            const data = {
               _id: item.meal._id,
               quantity: 1,
               name: item.meal.name,
               description: item.meal.description,
               amount: item.meal.price,
               price: item.meal.price,
               img_url: item.meal.img_url,
            };
            dispatch(cartActions.addToCart(data));
         }
      }

      setAddedToCart((t) => !t);
   };

   // const handleRemoveFromCart = (cartItem) => {
   //    dispatch(cartActions.removeFromCart({_id: cartItem?._id}));
   // };

   // const handleAddToCart = useCallback(
   //    (item) => {
   //       const data = {
   //          meal_id: item._id || item.meal._id,
   //          quantity: 1,
   //          name: item.name || item.meal.name,
   //          description: item.description || item.meal.description,
   //          amount: item.price || item.meal.price,
   //          price: item.price || item.meal.price,
   //          img_url: item.img_url || item.meal.img_url,
   //       };
   //       console.log('data', data);
   //       dispatch(cartActions.addToCart(data));

   //       setAddedToCart((t) => !t);
   //    },
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   //    [],
   // );

   return (
      <div
         key={`dishCards-${data.id}`}
         data-aos="fade-up"
         // data-aos-duration="1000"
         className={`dishCards ${className}`}>
         <div className="contents pb-md-3 pb-0">
            <div onClick={() => handleViewItem(data)} className="view">
               <div className="img d-flex align-items-center justify-content-center">
                  {img_url || meal?.img_url ? (
                     <img src={img_url || meal?.img_url} alt="" className="h-100 w-100" />
                  ) : (
                     <img src={logoImg} alt="" className="noImg" />
                  )}
               </div>
               <p className="title pt-md-3 pt-1" data-toggle="tooltip" data-placement="top" title={name || meal?.name}>
                  {name || meal?.title}
               </p>
               <p className="desc" data-toggle="tooltip" data-placement="top" title={description || meal?.description}>
                  {description || meal?.description}
               </p>
            </div>
            <hr className="my-md-3 mt-1 mb-2" style={{background: '#545F8A', opacity: '.7'}} />
            <div className="low d-flex align-items-center justify-content-between">
               <span className="fs-md-4 fs-6">&#8358; {numberWithCommas(price || meal?.price)}</span>
               <button
                  onClick={() => handleAddToCart(data)}
                  data-hover="CLICK ME"
                  className={classNames('addToCart text-nowrap', {addedToCart})}>
                  {addedToCart ? 'Remove from cart' : 'Add to cart'}
               </button>
            </div>
         </div>
      </div>
   );
};

export default DishCards;
