import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {RWebShare} from 'react-web-share';
import {FacebookShareButton, InstapaperShareButton, TwitterShareButton} from 'react-share';
import classNames from 'classnames';
import {useMediaQuery} from 'react-responsive';
import parse from 'html-react-parser';
import {BlogWrapper, Pagination} from '../../_components';
import {blogPost} from '../../_constants';
import {readableDateString} from '../../_helpers';
import BlogComments from './BlogComments';
import {blogActions} from '../../_actions';
import './index.scss';

import {ReactComponent as DateIcon} from '../../_assets/icons/date.svg';
import {ReactComponent as CommentIcon} from '../../_assets/icons/comments.svg';
import {ReactComponent as ShareIcon} from '../../_assets/icons/new-share.svg';
// import {ReactComponent as InstagramIcon} from '../../_assets/icons/instagram.svg';
// import {ReactComponent as LinkedInIcon} from '../../_assets/icons/linkedin.svg';
import {ReactComponent as FacebookIcon} from '../../_assets/icons/facebook.svg';
import {ReactComponent as TwitterIcon} from '../../_assets/icons/twitter.svg';

const ViewPost = () => {
   const {state} = useLocation();
   const dispatch = useDispatch();
   const {blogDetails, recentBlogsList} = useSelector((s) => s.blog);

   const blog = state?.blog;
   const navigate = useNavigate();
   const [active, setActive] = useState(blog.id || blogPost.id);
   // const {id, img, title, date, post, tags} = blog || blogPost[active];
   const mobileView = useMediaQuery({query: '(max-width: 767px)'});

   useEffect(() => {
      dispatch(blogActions.getABlog({_id: blog?._id}));
      dispatch(blogActions.getRecentBlogs());

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleChangePost = useCallback(
      (itm) => {
         setActive(itm);
         navigate(`/blog/${itm?.title}`, {state: {blog: itm}});
         dispatch(blogActions.getABlog({_id: itm?._id}));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [navigate],
   );

   return (
      <BlogWrapper>
         <div data-aos="fade-up" data-aos-duration="500" className="content __blog container p-md-0 py-md-5 py-4">
            {/* <h3 className="blogTitle text-center ps-2 text-capitalize">Our Blog</h3> */}
            <p className="blogSubTitle text-center ps-2 pb-md-4 pb-2">Check out the trending news in the food world.</p>
            <div className="row m-0 h-100">
               <div className="col-md-4 col-12 pe-lg-4">
                  <div className="popularPosts p-md-4 p-2">
                     <p className="text-center cat py-md-3 pb-md-4 pb-2">Recent Posts</p>
                     <ul className="w-100">
                        {recentBlogsList.map((item) => (
                           <li
                              key={`popular_posts-${item._id}`}
                              onClick={() => handleChangePost(item)}
                              className={classNames('p-md-3 px-2 mb-md-3 mx-md-0 mx-2 d-flex align-items-center', {
                                 active: active === item,
                              })}>
                              <div className="img me-md-4 me-2 d-none d-lg-flex">
                                 <img src={item.img_url} alt="" className="" />
                              </div>
                              <div className="label text-capitalize me-lg-2 text-nowrap">
                                 <p className="">{item.title}</p>
                                 <span className="d-none d-lg-flex">{readableDateString(item.createdAt)}</span>
                              </div>
                           </li>
                        ))}
                     </ul>
                  </div>

                  {/* <div className="comments py-5 d-none d-md-flex">
                     <p className="">Comments</p>
                     <ul className="w-100">
                        {comments.map((item, index) => (
                           <li key={`categories-${item.id}`} className="p-3 d-flex align-items-center">
                              <div className="img me-2">
                                 <img src={item.img} alt="" className="" />
                              </div>
                              <div className="label text-capitalize">{item.label}</div>
                              <span className="">{item.noOfItem}</span>
                           </li>
                        ))}
                     </ul>
                  </div> */}
               </div>

               <div className="col-md-8 col-12 ps-lg-4 mt-md-0 mt-4">
                  <div
                     key={blogDetails?._id || blog?._id}
                     data-aos="fade-up"
                     data-aos-duration="1000"
                     className="readBlog p-md-5 p-3">
                     <div className="img">
                        <img src={blogDetails?.img_url || blog?.img_url} alt="" className="h-100 w-100" />
                     </div>
                     <h4 className="py-4">{blogDetails?.title || blog?.title || blogPost.title}</h4>
                     <div className="d-flex align-items-center">
                        <div className="date me-5">
                           <div className="icon me-2">
                              <DateIcon className="h-100 w-100" />
                           </div>
                           <span className="">{readableDateString(blogDetails?.createdAt || blog?.createdAt)}</span>
                        </div>
                        <div className="commentsCount">
                           <div className="icon me-2">
                              <CommentIcon className="h-100 w-100" />
                           </div>
                           <span className="">
                              {blogDetails?.totalComments || blog?.totalComments}{' '}
                              {blogDetails?.totalComments || blog?.totalComments === (1 || 0) ? 'Comment' : 'Comments'}
                           </span>
                        </div>
                     </div>
                     <hr className="my-md-3 my-2" />
                     <div className="post pb-4">{parse(blogDetails?.body || blog?.body)}</div>
                     <hr className="my-md-3 my-2" />
                     <div className="tagging mb-5 d-flex flex-wrap align-items-center justify-content-between">
                        {/* <div className="d-flex flex-wrap align-items-center">
                           <span className="tags">Tags:</span>
                           {tags.map((item) => (
                              <span key={`tags-${item.id}`} className="tag mx-md-3 mx-1">
                                 {item.label}
                              </span>
                           ))}
                        </div> */}
                        <div className="d-flex flex-wrap align-items-center">
                           <span className="tags me-3">Share:</span>
                           <ul className="socialMedia pt-2 d-flex align-items-center justify-content-md-start justify-content-center">
                              {mobileView ? (
                                 <RWebShare
                                    data={{
                                       text: blogDetails?.body || blog?.body,
                                       url: `https://www.lagosteahouse.com/blog/${
                                          blogDetails?.title || blog?.title || blogPost.title
                                       }`,
                                       title: blogDetails?.title || blog?.title || blogPost.title,
                                    }}
                                    onClick={() => console.log('shared successfully!')}>
                                    <ShareIcon className="icon" />
                                 </RWebShare>
                              ) : (
                                 <>
                                    <FacebookShareButton
                                       url={`https://www.lagosteahouse.com/blog/${
                                          blogDetails?.title || blog?.title || blogPost.title
                                       }`}
                                       quote={blogDetails?.title || blog?.title || blogPost.title}
                                       hashtag={`#${blogDetails?.title || blog?.title || blogPost.title}`}
                                       description={blogDetails?.body || blog?.body}
                                       className="Demo__some-network__share-button social__icon">
                                       <li className="">
                                          <FacebookIcon className="icon" style={{transform: 'scale(.75)'}} />
                                       </li>
                                    </FacebookShareButton>

                                    <TwitterShareButton
                                       url={`https://www.lagosteahouse.com/blog/${
                                          blogDetails?.title || blog?.title || blogPost.title
                                       }`}
                                       quote={blogDetails?.title || blog?.title || blogPost.title}
                                       hashtag={`#${blogDetails?.title || blog?.title || blogPost.title}`}
                                       description={blogDetails?.body || blog?.body}
                                       className="Demo__some-network__share-button mx-3 social__icon">
                                       <li className="">
                                          <TwitterIcon className="icon" style={{transform: 'scale(.75)'}} />
                                       </li>
                                    </TwitterShareButton>

                                    {/* <InstapaperShareButton
                                       url={`https://www.lagosteahouse.com/blog/${
                                          blogDetails?.title || blog?.title || blogPost.title
                                       }`}
                                       quote={blogDetails?.title || blog?.title || blogPost.title}
                                       hashtag={`#${blogDetails?.title || blog?.title || blogPost.title}`}
                                       description={blogDetails?.body || blog?.body}
                                       className="Demo__some-network__share-button social__icon">
                                       <li className="">
                                          <InstagramIcon className="icon" style={{transform: 'scale(.75)'}} />
                                       </li>
                                    </InstapaperShareButton> */}
                                 </>
                              )}
                           </ul>
                        </div>
                     </div>

                     {/* <Pagination /> */}
                     <hr className="my-md-3 my-2" />
                     <BlogComments blog={blogDetails || blog} />
                  </div>
               </div>
            </div>
         </div>
      </BlogWrapper>
   );
};

export default ViewPost;
