import React, {useState} from 'react';
import classNames from 'classnames';
import {ImgWrapper, Reservations} from '../../../../_components';
import {useMediaQuery} from 'react-responsive';
import './index.scss';

import home1 from '../../../../_assets/images/home111.jpg';
import home2 from '../../../../_assets/images/home4.jpg';
import home222 from '../../../../_assets/images/home222.jpg';
import {ReactComponent as GreenFlowerIcon} from '../../../../_assets/icons/greenFlower.svg';
import {ReactComponent as OrangeStarIcon} from '../../../../_assets/icons/orangeStar.svg';
import {ReactComponent as BgIcon} from '../../../../_assets/icons/healthyBg.svg';

const Section1 = () => {
   const mobile = useMediaQuery({query: '(max-width: 767px)'});
   const mobileView = useMediaQuery({query: '(max-width: 991px)'});
   const [modalOpen, setModalOpen] = useState(false);

   return (
      <section className={classNames('sectionOne container-fluid position-relative', {})}>
         <div className="healthyBg d-none d-md-flex position-absolute">
            <BgIcon className="icon h-100 w-100" />
         </div>
         <div className="content container p-md-0 mt-md-5 pt-md-5 pt-3">
            {/* <h1 data-aos="fade-up" data-aos-duration="500" className="intro pt-md-4 pt-4 pb-md-4 pb-3">
                  Get a <span className="">Healthy Eating</span> {!mobileView && <br />} Lifestyle From Us
               </h1> */}
            <div className="row m-0 banner">
               <div className="col-md-5 col-12 p-0">
                  <h1 data-aos="fade-up" className="intro pb-md-3 pb-3">
                     Get a <br /> <span className="">Healthy Eating</span> Lifestyle From Us
                  </h1>
                  <p data-aos="fade-up" className="desc">
                     We can provide you with the finest and healthy food {!mobileView && <br />} that you and your guest
                     will remember.
                  </p>
                  <button data-aos="zoom-in" onClick={() => setModalOpen(true)} className="reservations mt-4">
                     Reservations
                  </button>
                  <Reservations modalOpen={modalOpen} setModalOpen={setModalOpen} />
                  {/* <ImgWrapper className="img-1 w-100 d-none d-md-flex pe-md-4 mb-md-0 mb-5" img={home1}>
                        <OrangeStarIcon className="icon" />
                     </ImgWrapper> */}
               </div>
               <div className="col-md-7 col-12 p-0 d-flex align-items-end">
                  <div className="row m-0">
                     <div className="col-lg-5 pb-lg-0 pb-4">
                        <ImgWrapper className="img-2 w-100 d-none d-md-flex" img={home2} />
                     </div>
                     <div className="col-lg-7">
                        <ImgWrapper className="img-2 w-100 d-none d-md-flex" img={home222}>
                           <GreenFlowerIcon className="icon" />
                        </ImgWrapper>
                     </div>
                  </div>
                  {/* <ImgWrapper className="img-2 w-100 d-none d-md-flex" img={home2}>
                     <GreenFlowerIcon className="icon" />
                  </ImgWrapper> */}
               </div>
            </div>
            {mobile && (
               <div className="mobileView overflow-hidden">
                  <div className="inner pt-4 align-items-center">
                     <img src={home1} alt="" data-aos="fade-up" className="image-1" />
                     <GreenFlowerIcon data-aos="fade-up" className="iconMobile icon1" />
                     <div data-aos="fade-up" className="position-relative">
                        <img src={home222} alt="" className="image-2" />
                        {/* <OrangeStarIcon className="iconMobile icon2" /> */}
                     </div>
                     <div data-aos="fade-up" className="position-relative ms-5">
                        <img src={home2} alt="" className="image-2" />
                        <OrangeStarIcon className="iconMobile icon2" />
                     </div>
                  </div>
               </div>
            )}
         </div>
      </section>
   );
};

export default Section1;
