import {combineReducers} from 'redux';
import {cartConstants} from '../../_constants';

const IsRequestingCart = (state = false, action) => {
   switch (action.type) {
      case cartConstants.REQUEST_ALL_CART_ITEMS:
         return true;
      case cartConstants.REQUEST_ALL_CART_ITEMS_ERROR:
         return false;
      case cartConstants.REQUEST_ALL_CART_ITEMS_SUCCESS:
         return false;
      default:
         return state;
   }
};
const IsUpdatingItemQuantity = (state = false, action) => {
   switch (action.type) {
      case cartConstants.REQUEST_UPDATE_ITEM_QUANTITY_IN_CART:
         return true;
      case cartConstants.REQUEST_UPDATE_ITEM_QUANTITY_IN_CART_ERROR:
         return false;
      case cartConstants.REQUEST_UPDATE_ITEM_QUANTITY_IN_CART_SUCCESS:
         return false;
      default:
         return state;
   }
};

const cartList = (state = [], action) => {
   switch (action.type) {
      case cartConstants.REQUEST_ALL_CART_ITEMS_SUCCESS:
         return action.cartItems;
      case cartConstants.REQUEST_ADD_TO_CART_SUCCESS:
         return action.newList;
      case cartConstants.REQUEST_UPDATE_ITEM_QUANTITY_IN_CART_SUCCESS:
         return action.newList;
      case cartConstants.REQUEST_REMOVE_FROM_CART_SUCCESS:
         return action.newList;
      case cartConstants.REQUEST_CLEAR_CART_SUCCESS:
         return action.newList;
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingCart,
   IsUpdatingItemQuantity,
   cartList,
});

export default rootReducer;
