import {combineReducers} from 'redux';
import {contactConstants} from '../../_constants';

const IsContactingAdmin = (state = false, action) => {
   switch (action.type) {
      case contactConstants.REQUEST_CONTACT_ADMIN:
         return true;
      case contactConstants.CONTACT_ADMIN_SUCCESS:
         return false;
      case contactConstants.CONTACT_ADMIN_ERROR:
         return false;
      default:
         return state;
   }
};

const adminContactedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case contactConstants.REQUEST_CONTACT_ADMIN:
         return false;
      case contactConstants.CONTACT_ADMIN_SUCCESS:
         return true;
      case contactConstants.CONTACT_ADMIN_ERROR:
         return false;
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsContactingAdmin,
   adminContactedSuccessfully,
});

export default rootReducer;
