import React from 'react';
import {ImgWrapper} from '../../../../_components';
import {Link} from 'react-router-dom';
import './index.scss';

import home3 from '../../../../_assets/images/home33.jpg';
import home4 from '../../../../_assets/images/home44.jpg';
import {ReactComponent as BgIcon} from '../../../../_assets/icons/wlcmBg.svg';
import {ReactComponent as NutsIcon} from '../../../../_assets/icons/nuts.svg';
import {ReactComponent as CurveIcon} from '../../../../_assets/icons/curves.svg';

const Section2 = () => {
   return (
      <section className="sectionTwo container-fluid p-0 position-relative overflow-hidden">
         <div className="wlcmBg position-absolute">
            <BgIcon className="icon h-100 w-100" />
         </div>
         <div className="content container p-md-0">
            <div className="row m-0 banner py-md-5 pt-5 pb-5">
               <div className="col-md-6 col-12 order-md-1 order-2 py-md-5 py-4 d-flex justify-content-center">
                  <ImgWrapper className="img-1 ps-3 pe-4" img={home3}>
                     <CurveIcon className="icon" />
                  </ImgWrapper>
                  <ImgWrapper className="img-2" img={home4}>
                     <NutsIcon className="icon" />
                  </ImgWrapper>
               </div>
               <div className="col-md-6 col-12 order-md-2 order-1 py-md-5 py-4 d-flex flex-column justify-content-center">
                  <div className="welcome">
                     <h6 data-aos="fade-up" className="text-uppercase pb-md-4 pb-2">
                        welcome to
                     </h6>
                     <h1 data-aos="fade-up" className="text-uppercase py-md-0 py-2">
                        LAGOS TEA HOUSE
                     </h1>
                     <p data-aos="fade-up" className="py-md-4 py-2 pb-md-5 pb-4">
                        Lagos Tea House is a contemporary café and restaurant which delights in serving healthy Teas
                        from our over 50 assorted loose leaves Teas, coffees, smoothies, juices, and of course very
                        tasty and yummy food and bakes.
                     </p>
                     <Link
                        to="/about"
                        data-aos="fade-up"
                        // data-aos-duration="1000"
                        className="about mt-md-4 mt-4 text-capitalize me-auto">
                        about us
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Section2;
