import {call, put, takeLatest, all} from 'redux-saga/effects';
import {userConstants, appConstants} from '../../_constants';
import {appActions} from '../../_actions';
import {
   setObjectInStorage,
   getObjectFromStorage,
   clearObjectFromStorage,
   createRequest,
   createRequestWithToken,
   checkStatus,
   parseJSON,
} from '../../_helpers';
//import crashlytics from '@react-native-firebase/crashlytics';

function* loginUserSaga({data}) {
   yield put({type: userConstants.LOGIN_REQUEST});

   try {
      const curriedReq = yield call(createRequest, userConstants.LOGIN_URI, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield call(setObjectInStorage, userConstants.USER_KEY, jsonResponse);
      yield put({
         type: userConstants.LOGIN_SUCCESS,
         user: jsonResponse,
      });
   } catch (error) {
      let errorRes;
      if (error.response) {
         errorRes = yield call(parseJSON, error.response);
      }
      const errorMessage = errorRes ? errorRes : error;
      yield put({type: userConstants.LOGIN_FAILURE, error: errorMessage});
   }
}

function* logoutUserSaga() {
   yield put({type: userConstants.LOGOUT_REQUEST});
   try {
      yield call(clearObjectFromStorage, userConstants.USER_KEY);

      yield put({type: userConstants.LOGOUT_SUCCESS});
   } catch (error) {
      //crashlytics().recordError(error);
      yield put({type: userConstants.LOGOUT_FAILURE});
   }
}

function* verifyEmailSaga({data}) {
   yield put({type: userConstants.REQUEST_VERIFY_EMAIL});

   try {
      const verifyEmailUri = `${userConstants.VERIFY_EMAIL_URI}${data.email}/verify/${data.token}`;

      const req = yield call(createRequest, verifyEmailUri, {
         method: 'GET',
      });

      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(parseJSON, response);
      yield call(setObjectInStorage, userConstants.USER_KEY, jsonResponse);

      yield put({
         type: userConstants.VERIFY_EMAIL_SUCCESS,
         verifiedUser: jsonResponse,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);

         yield put({
            type: userConstants.VERIFY_EMAIL_ERROR,
            error: res,
         });

         return;
      }

      yield put({
         type: userConstants.VERIFY_EMAIL_ERROR,
         error: error.message,
      });
   }
}

function* setPassword({data}) {
   yield put({type: userConstants.REQUEST_SET_PASSWORD});

   try {
      const url = `${userConstants.SET_USER_PASSWORD_URI}`;
      // const url = `${userConstants.SET_USER_PASSWORD_URI}/admin_set_password/${data.id}`;

      const req = yield call(createRequest, url, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: userConstants.SET_PASSWORD_SUCCESS, user: jsonResponse});

      yield put(
         appActions.setSnackBar({
            message: 'User successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: userConstants.SET_PASSWORD_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message ? errorMessage.message : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* changePassword({data}) {
   yield put({type: userConstants.REQUEST_CHANGE_PASSWORD});

   try {
      const user = yield call(getObjectFromStorage, userConstants.USER_KEY);

      const url = `${userConstants.CHANGE_PASSWORD_URI}`;

      // const req = yield call(createRequest, url, {
      //    method: 'PATCH',
      //    body: JSON.stringify(data),
      // });

      const req = createRequestWithToken(url, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.access_token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: userConstants.CHANGE_PASSWORD_SUCCESS,
         password: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: 'Password successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: userConstants.CHANGE_PASSWORD_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message ? errorMessage.message : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* getStates({data}) {
   yield put({type: appConstants.REQUEST_ALL_STATES});

   try {
      const user = yield call(getObjectFromStorage, userConstants.USER_KEY);
      let statesUri = `${appConstants.STATE_URL}`;

      const statesReq = createRequestWithToken(statesUri, {
         method: 'GET',
      })(user?.token);

      const response = yield call(fetch, statesReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: appConstants.REQUEST_ALL_STATES_SUCCESS,
         states: jsonResponse,
      });
   } catch (error) {
      yield put({type: appConstants.REQUEST_ALL_STATES_ERROR});
   }
}

// function* resetPassword({data}) {
//    yield put({type: userConstants.RESET_PASSWORD_REQUEST});

//    try {
//       const url = `${userConstants.GET_ADMIN_USER_URI}/reset_password`;

//       const req = createRequestWithToken(url, {
//          method: 'PATCH',
//          body: JSON.stringify(data),
//       })(data.token);

//       const response = yield call(fetch, req);

//       yield call(checkStatus, response);

//       const jsonResponse = yield call(response.json.bind(response));

//       yield put({
//          type: userConstants.RESET_PASSWORD_SUCCESS,
//          user: jsonResponse,
//       });

//       yield put(
//          appActions.setSnackBar({
//             message: 'Password successfully changed',
//             variant: 'success',
//          }),
//       );
//    } catch (error) {
//       const errorMessage = yield call(error.response.json.bind(error.response));

//       yield put({
//          type: userConstants.RESET_PASSWORD_FAILURE,
//          error: errorMessage,
//       });
//       yield put(
//          appActions.setSnackBar({
//             message: errorMessage.message
//                ? errorMessage.message
//                : 'Something went wrong',
//             variant: 'error',
//          }),
//       );
//    }
// }

function* loginUserSagaWatcher() {
   yield takeLatest(userConstants.LOGIN, loginUserSaga);
}

function* logoutUserSagaWatcher() {
   yield takeLatest(userConstants.LOGOUT, logoutUserSaga);
}

function* verifyEmailWatcher() {
   yield takeLatest(userConstants.VERIFY_EMAIL, verifyEmailSaga);
}

function* setPasswordWatcher() {
   yield takeLatest(userConstants.SET_PASSWORD, setPassword);
}

function* changePasswordWatcher() {
   yield takeLatest(userConstants.CHANGE_PASSWORD, changePassword);
}

function* getStatesWatcher() {
   yield takeLatest(appConstants.GET_STATES, getStates);
}

export default function* rootSaga() {
   yield all([
      loginUserSagaWatcher(),
      logoutUserSagaWatcher(),
      verifyEmailWatcher(),
      setPasswordWatcher(),
      changePasswordWatcher(),
      getStatesWatcher(),
   ]);
}
