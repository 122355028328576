import React from 'react';
import {withFormsy} from 'formsy-react';
import classNames from 'classnames';

const Checker = withFormsy((props) => {
   const changeValue = (e) => {
      // setValue() will set the value of the component, which in
      // turn will validate it and the rest of the form
      // Important: Don't skip this step. This pattern is required
      // for Formsy to work.
      props.setValue(e.currentTarget.checked);
   };
   return (
      <div className={classNames('checkbox d-flex align-items-center', props.className)}>
         <input checked={props.value} onChange={changeValue} type="checkbox" className="me-2" name={props.name} />
         <label htmlFor={props.name} className="mt-1">
            {props.label}
         </label>
      </div>
   );
});

export default Checker;
