import React from 'react';
import { Section1, Section2, Section3, Section4, Section5 } from './_component';
import './index.scss';

const About = () => {
   return (
      <div className="__home">
         <Section1 />
         <Section2 />
         <Section3 />
         <Section4 />
         <Section5 />
      </div>
   );
};

export default About;
