import React from 'react';
import {useNavigate} from 'react-router-dom';
import parse from 'html-react-parser';
import './index.scss';

const BlogCard = ({className, data, id, img_url, title, excerpt, date}) => {
   const navigate = useNavigate();

   const handleViewItem = (item) => {
      navigate(`/blog/${item?.title}`, {state: {blog: item}});
   };

   return (
      <div
         key={`blogCard-${id}`}
         data-aos="zoom-in"
         data-aos-duration="750"
         className={`blogCard p-md-4 p-2 mb-md-0 mb-4 ${className}`}>
         <div className="img position-relative">
            <img src={img_url} alt="" className="h-100 w-100" />
            <div className="blogPost position-absolute">
               <div className="content px-md-4 px-3 d-flex flex-column justify-content-between">
                  <p className="title pt-md-4 pt-3 text-uppercase">{title}</p>
                  <p className="post">{parse(excerpt)}</p>
                  <div className="pb-md-4 pb-3 d-flex align-items-center justify-content-between">
                     <button onClick={() => handleViewItem(data)} className="readMore text-nowrap">
                        Read more
                     </button>
                     <span className="date text-nowrap">{date}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default BlogCard;
