import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {RelatedDishes} from '../../_components';
// import {cartItem, relatedDishes} from '../../_constants';
import {numberWithCommas} from '../../_helpers';
import BillingDetails from './BillingDetails';
import CartRow from './CartRow';
import './index.scss';

import {ReactComponent as PaidIcon} from '../../_assets/icons/cartPaid.svg';
import {menuActions} from '../../_actions';

const Cart = () => {
   const dispatch = useDispatch();

   const cartList = useSelector((s) => s.cart.cartList);
   const orderDetails = useSelector((s) => s.order.orderDetails);
   const popularMenusList = useSelector((s) => s.menu.popularMenusList);

   useEffect(() => {
      dispatch(menuActions.getPopularMenus());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [cartState, setCartState] = useState(1);
   // const [billing, setBilling] = useState(false);
   let amount = 0;
   for (var i = 0; i < cartList.length; i++) {
      amount += cartList[i].amount;
   }

   // const handleAddToCart = useCallback(() => {
   //    dispatch(
   //       appActions.setSnackBar({
   //          message: `${state.title} has been added to your cart!`,
   //          variant: 'success',
   //          img: state.img,
   //       }),
   //    );
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [state.img, state.title]);

   return (
      <section className="__cart container-fluid p-0 overflow-hidden">
         <div className="content container mb-lg-4">
            <h2 data-aos="fade-up" data-aos-duration="500" className="pb-4 pt-3 ps-md-0 ps-3">
               Your Cart
            </h2>
            {cartState === 1 && (
               <div className="inner row m-0">
                  <div className="col-lg-8 mb-lg-0 mb-4">
                     <div data-aos="fade-up" data-aos-duration="750" className="cartItems p-4">
                        <ul className="">
                           {cartList?.length > 0 ? (
                              cartList.map((item) => <CartRow item={item} />)
                           ) : (
                              <h4>No items in your cart</h4>
                           )}
                        </ul>
                     </div>
                  </div>

                  <div className="col-lg-4">
                     <div data-aos="fade-up" data-aos-duration="1000" className="cartTotals p-4">
                        <div className="contents p-4">
                           <p className="title">Cart Totals</p>
                           <hr />
                           <div className="pb-3 d-flex align-items-center justify-content-between">
                              <p className="orderValue">Order Value</p>
                              <p className="amount">&#8358; {numberWithCommas(amount)}</p>
                           </div>
                           <div className="d-flex align-items-center justify-content-between">
                              <p className="orderValue">Delivery</p>
                              <p className="amount">&#8358; 0.00</p>
                           </div>
                           <hr />
                           <div className="d-flex align-items-center justify-content-between">
                              <p className="total">Total</p>
                              <p className="amount">&#8358; {numberWithCommas(amount)}.00</p>
                           </div>
                        </div>
                        <button
                           disabled={cartList?.length === 0}
                           onClick={() => setCartState(2)}
                           className="continue mt-4 w-100">
                           Continue
                        </button>
                     </div>
                  </div>
               </div>
            )}

            {cartState === 2 && (
               <>
                  <div className="inner pt-3">
                     <div
                        data-aos="fade-up"
                        data-aos-duration="750"
                        className="cartItems p-4 d-flex align-items-center justify-content-between">
                        <p className="orderSummary text-uppercase">order summary</p>
                        <h4 className="price">&#8358; {numberWithCommas(amount)}</h4>
                     </div>
                  </div>
                  <BillingDetails amount={amount} cartList={cartList} setCartState={setCartState} />
               </>
            )}

            {cartState === 3 && (
               <div className="billingDetails p-5 d-flex flex-column align-items-center justify-content-center">
                  <PaidIcon className="" style={{transform: 'scale(.6)'}} />
                  <p className="">
                     You have successfully paid{' '}
                     <span className="price ms-2">&#8358; {numberWithCommas(orderDetails?.payment?.amount_paid)}</span>
                  </p>
                  <hr className="w-50 my-4" style={{background: 'rgba(84, 95, 138, 0.83)'}} />
                  <p className="mb-4">Check your email for the Estimated Delivery Date.</p>
                  <Link to="/menu" className="submit mt-4">
                     Continue Shopping
                  </Link>
               </div>
            )}
         </div>
         {popularMenusList.length > 0 && <RelatedDishes data={popularMenusList} />}
      </section>
   );
};

export default Cart;
