import {combineReducers} from 'redux';
import {appConstants, forgotPasswordConstants} from '../../_constants';
import {updateObject} from '../../_helpers';

const IsSendingResetPasswordLink = (state = false, action) => {
   switch (action.type) {
      case forgotPasswordConstants.REQUEST_SEND_RESET_PASSWORD_LINK:
         return true;
      case forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_SUCCESS:
         return false;
      case forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_ERROR:
         return false;
      default:
         return state;
   }
};

const successMessage = (state = '', action) => {
   switch (action.type) {
      case forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_SUCCESS:
         return action.resetPasswordLink;
      case forgotPasswordConstants.RESET_PASSWORD_SUCCESS:
         return action.user;
      case appConstants.CLEAR_MESSAGES:
         return '';
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_ERROR:
         return updateObject(state, error);
      case forgotPasswordConstants.REQUEST_SEND_RESET_PASSWORD_LINK:
         return null;
      case forgotPasswordConstants.RESET_PASSWORD_ERROR:
         return error;
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

const successMessageIsSent = (state = false, action) => {
   switch (action.type) {
      case forgotPasswordConstants.REQUEST_SEND_RESET_PASSWORD_LINK:
         return false;
      case forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_SUCCESS:
         return true;
      case forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_ERROR:
         return false;
      case forgotPasswordConstants.REQUEST_RESET_PASSWORD:
         return false;
      case forgotPasswordConstants.RESET_PASSWORD_SUCCESS:
         return true;
      case forgotPasswordConstants.RESET_PASSWORD_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const errorMessageIsSent = (state = false, action) => {
   switch (action.type) {
      case forgotPasswordConstants.REQUEST_SEND_RESET_PASSWORD_LINK:
         return false;
      case forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_SUCCESS:
         return false;
      case forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_ERROR:
         return true;
      case forgotPasswordConstants.REQUEST_RESET_PASSWORD:
         return false;
      case forgotPasswordConstants.RESET_PASSWORD_SUCCESS:
         return false;
      case forgotPasswordConstants.RESET_PASSWORD_ERROR:
         return true;
      default:
         return state;
   }
};

const resetPasswordSuccessMessage = (state = '', action) => {
   switch (action.type) {
      case forgotPasswordConstants.RESET_PASSWORD_SUCCESS:
         return action.user;
      default:
         return state;
   }
};

const isResettingPassword = (state = false, action) => {
   switch (action.type) {
      case forgotPasswordConstants.REQUEST_RESET_PASSWORD:
         return true;
      case forgotPasswordConstants.RESET_PASSWORD_SUCCESS:
         return false;
      case forgotPasswordConstants.RESET_PASSWORD_ERROR:
         return false;
      default:
         return state;
   }
};

const resetPasswordSuccessful = (state = false, action) => {
   switch (action.type) {
      case forgotPasswordConstants.REQUEST_RESET_PASSWORD:
         return false;
      case forgotPasswordConstants.RESET_PASSWORD_SUCCESS:
         return true;
      case forgotPasswordConstants.RESET_PASSWORD_ERROR:
         return false;
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsSendingResetPasswordLink,
   successMessage,
   errorMessage,
   successMessageIsSent,
   isResettingPassword,
   errorMessageIsSent,
   resetPasswordSuccessMessage,
   resetPasswordSuccessful,
});

export default rootReducer;
