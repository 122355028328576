import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {BlogCard} from '../../../../_components';
// import {blogPost} from '../../../../_constants';
import './index.scss';

// import {ReactComponent as BgIcon} from '../../../../_assets/icons/wlcmBg.svg';

const Section6 = () => {
   const {blogsList} = useSelector((s) => s.blog);

   return (
      <section className="sectionSix container-fluid p-0 overflow-hidden">
         <div className="content container p-md-0 py-md-5 py-4 my-4">
            <h6 data-aos="zoom-in" className="text-uppercase text-center">
               our blog
            </h6>
            <h1 data-aos="zoom-in" className="text-uppercase text-center py-md-4 py-3">
               trending news
            </h1>
            <div className="row m-0 banner">
               {blogsList &&
                  blogsList.length > 0 &&
                  blogsList
                     .slice(0, 3)
                     .map((posts) => (
                        <BlogCard data={posts} {...posts} className="trendingNews col-lg-4 col-sm-6 col-12" />
                     ))}
            </div>
            <div className="mt-4 d-flex align-item-center justify-content-center">
               <Link data-aos="fade-up" to="/blog" className="learnMore">
                  Learn more
               </Link>
            </div>
         </div>
      </section>
   );
};

export default Section6;
