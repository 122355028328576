// import {appConstants} from './app.constant';

export const cartConstants = {
   REQUEST_ALL_CART_ITEMS: 'REQUEST_ALL_CART_ITEMS',
   REQUEST_ALL_CART_ITEMS_SUCCESS: 'REQUEST_ALL_CART_ITEMS_SUCCESS',
   REQUEST_ALL_CART_ITEMS_SUCCESS_WITHOUT_DATA: 'REQUEST_ALL_CART_ITEMS_SUCCESS_WITHOUT_DATA',
   REQUEST_ALL_CART_ITEMS_ERROR: 'REQUEST_ALL_CART_ITEMS_ERROR',

   REQUEST_ADD_TO_CART: 'REQUEST_ADD_TO_CART',
   REQUEST_ADD_TO_CART_SUCCESS: 'REQUEST_ADD_TO_CART_SUCCESS',
   REQUEST_ADD_TO_CART_ERROR: 'REQUEST_ADD_TO_CART_ERROR',

   REQUEST_UPDATE_ITEM_QUANTITY_IN_CART: 'REQUEST_UPDATE_ITEM_QUANTITY_IN_CART',
   REQUEST_UPDATE_ITEM_QUANTITY_IN_CART_SUCCESS: 'REQUEST_UPDATE_ITEM_QUANTITY_IN_CART_SUCCESS',
   REQUEST_UPDATE_ITEM_QUANTITY_IN_CART_ERROR: 'REQUEST_UPDATE_ITEM_QUANTITY_IN_CART_ERROR',

   REQUEST_REMOVE_FROM_CART: 'REQUEST_REMOVE_FROM_CART',
   REQUEST_REMOVE_FROM_CART_SUCCESS: 'REQUEST_REMOVE_FROM_CART_SUCCESS',
   REQUEST_REMOVE_FROM_CART_ERROR: 'REQUEST_REMOVE_FROM_CART_ERROR',

   REQUEST_CLEAR_CART: 'REQUEST_CLEAR_CART',
   REQUEST_CLEAR_CART_SUCCESS: 'REQUEST_CLEAR_CART_SUCCESS',
   REQUEST_CLEAR_CART_ERROR: 'REQUEST_CLEAR_CART_ERROR',

   GET_ALL_CART_ITEMS: 'GET_ALL_CART_ITEMS',
   ADD_TO_CART: 'ADD_TO_CART',
   UPDATE_ITEM_QUANTITY_IN_CART: 'UPDATE_ITEM_QUANTITY_IN_CART',

   REMOVE_FROM_CART: 'REMOVE_FROM_CART',
   CLEAR_CART: 'CLEAR_CART',
   CART_KEY: 'cart',

   // MENU_URI: `${appConstants.BASE_URI}${menu}`,
   // CATEGORY_MENU_URI: `${appConstants.BASE_URI}${menu}/category`,
};
