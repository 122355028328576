import {appConstants} from './app.constant';

const menu = 'meal';

export const menuConstants = {
   REQUEST_ALL_MENUS: 'REQUEST_ALL_MENUS',
   REQUEST_ALL_MENUS_SUCCESS: 'REQUEST_ALL_MENUS_SUCCESS',
   REQUEST_ALL_MENUS_SUCCESS_WITHOUT_DATA: 'REQUEST_ALL_MENUS_SUCCESS_WITHOUT_DATA',
   REQUEST_ALL_MENUS_ERROR: 'REQUEST_ALL_MENUS_ERROR',

   REQUEST_POPULAR_MENUS: 'REQUEST_POPULAR_MENUS',
   REQUEST_POPULAR_MENUS_SUCCESS: 'REQUEST_POPULAR_MENUS_SUCCESS',
   REQUEST_POPULAR_MENUS_ERROR: 'REQUEST_POPULAR_MENUS_ERROR',

   REQUEST_CATEGORY_MENUS: 'REQUEST_CATEGORY_MENUS',
   REQUEST_CATEGORY_MENUS_SUCCESS: 'REQUEST_CATEGORY_MENUS_SUCCESS',
   REQUEST_CATEGORY_MENUS_ERROR: 'REQUEST_CATEGORY_MENUS_ERROR',

   REQUEST_A_MENU: 'REQUEST_A_MENU',
   REQUEST_A_MENU_SUCCESS: 'REQUEST_A_MENU_SUCCESS',
   REQUEST_A_MENU_ERROR: 'REQUEST_A_MENU_ERROR',

   REQUEST_COMMENT_MENU: 'REQUEST_COMMENT_MENU',
   REQUEST_COMMENT_MENU_SUCCESS: 'REQUEST_COMMENT_MENU_SUCCESS',
   REQUEST_COMMENT_MENU_ERROR: 'REQUEST_COMMENT_MENU_ERROR',

   REQUEST_SEARCH_MENU: 'REQUEST_SEARCH_MENU',
   SEARCH_MENU_SUCCESS: 'SEARCH_MENU_SUCCESS',
   SEARCH_MENU_ERROR: 'SEARCH_MENU_ERROR',
   SEARCH_MENU_SUCCESS_WITHOUT_DATA: 'SEARCH_MENU_SUCCESS_WITHOUT_DATA',

   GET_ALL_MENUS: 'GET_ALL_MENUS',
   SEARCH_MENU: 'SEARCH_MENU',
   GET_A_MENU: 'GET_A_MENU',

   GET_POPULAR_MENUS: 'GET_POPULAR_MENUS',
   GET_CATEGORY_MENUS: 'GET_CATEGORY_MENUS',
   COMMENT_MENU: 'COMMENT_MENU',

   MENU_URI: `${appConstants.BASE_URI}${menu}`,
   CATEGORY_MENU_URI: `${appConstants.BASE_URI}${menu}/category`,
};
