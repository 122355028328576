import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {menuConstants, userConstants} from '../../_constants';
import {createRequest, getObjectFromStorage, checkStatus, createRequestWithToken, parseJSON} from '../../_helpers';

function* getAllMenus({data}) {
   yield put({type: menuConstants.REQUEST_ALL_MENUS});
   try {
      const menuUri = `${menuConstants.MENU_URI}`;
      const req = yield call(createRequest, menuUri, {method: 'GET'});
      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: menuConstants.REQUEST_ALL_MENUS_SUCCESS, menus: jsonResponse});
   } catch (error) {
      yield put({type: menuConstants.REQUEST_ALL_MENUS_ERROR});
   }
}

function* getPopularMenus() {
   yield put({type: menuConstants.REQUEST_POPULAR_MENUS});
   try {
      const menuUri = `${menuConstants.MENU_URI}/front`;
      const req = yield call(createRequest, menuUri, {method: 'GET'});
      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: menuConstants.REQUEST_POPULAR_MENUS_SUCCESS, popularMenus: jsonResponse});
   } catch (error) {
      yield put({type: menuConstants.REQUEST_POPULAR_MENUS_ERROR});
   }
}

function* getCategoryMenus({data}) {
   yield put({type: menuConstants.REQUEST_CATEGORY_MENUS});
   try {
      let categoryMenuUri = `${menuConstants.CATEGORY_MENU_URI}/${data._id}`;
      if (data?.page) {
         categoryMenuUri = `${categoryMenuUri}?page=${data.page + 1}`;
      }
      const req = yield call(createRequest, categoryMenuUri, {method: 'GET'});
      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: menuConstants.REQUEST_CATEGORY_MENUS_SUCCESS, categoryMenus: jsonResponse});
   } catch (error) {
      yield put({type: menuConstants.REQUEST_CATEGORY_MENUS_ERROR});
   }
}

function* getAMenu({data}) {
   yield put({type: menuConstants.REQUEST_A_MENU});

   try {
      // let menusUri = `${appConstants.BASE_URI}${menuConstants.menu}/search`;

      // const menusReq = yield call(createRequest, menusUri, {method: 'POST', body: JSON.stringify(data)});

      // const response = yield call(fetch, menusReq);

      const menuUri = `${menuConstants.MENU_URI}/${data?._id}`;
      const req = yield call(createRequest, menuUri, {method: 'GET'});
      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({type: menuConstants.REQUEST_A_MENU_SUCCESS, menu: jsonResponse});
   } catch (error) {
      yield put({type: menuConstants.REQUEST_A_MENU_ERROR});
   }
}

function* commentMenuSaga({data}) {
   yield put({type: menuConstants.REQUEST_COMMENT_MENU});

   try {
      const commentUri = `${menuConstants.MENU_URI}/${data.menuId}/comment`;

      const curriedReq = yield call(createRequest, commentUri, {
         method: 'PATCH',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      // yield call(setObjectInStorage, menuConstants.reservation_KEY, jsonResponse);
      yield put({
         type: menuConstants.REQUEST_COMMENT_MENU_SUCCESS,
         comment: jsonResponse,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Comment successfully sent!',
            variant: 'success',
         }),
      );
   } catch (error) {
      let errorRes;
      if (error.response) {
         errorRes = yield call(parseJSON, error.response);
      }
      const errorMessage = errorRes ? errorRes : error;
      yield put({type: menuConstants.REQUEST_POPULAR_MENUS_ERROR, error: errorMessage});
   }
}

function* searchMenu({data}) {
   yield put({type: menuConstants.REQUEST_SEARCH_MENU});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      let menuUri = `${menuConstants.MENU_URI}/advanced_search`;

      const menuReq = createRequestWithToken(menuUri, {method: 'POST', body: JSON.stringify(data)})(user?.token);

      const response = yield call(fetch, menuReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({type: menuConstants.SEARCH_MENU_SUCCESS_WITHOUT_DATA});

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: menuConstants.SEARCH_MENU_SUCCESS, menu: jsonResponse});
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({type: menuConstants.SEARCH_MENU_ERROR, error: errorMessage});
   }
}

function* getAllMenusWatcher() {
   yield takeLatest(menuConstants.GET_ALL_MENUS, getAllMenus);
}

function* getPopularMenusWatcher() {
   yield takeLatest(menuConstants.GET_POPULAR_MENUS, getPopularMenus);
}

function* getCategoryMenusWatcher() {
   yield takeLatest(menuConstants.GET_CATEGORY_MENUS, getCategoryMenus);
}

function* getAMenuWatcher() {
   yield takeLatest(menuConstants.GET_A_MENU, getAMenu);
}

function* commentMenuSagaWatcher() {
   yield takeLatest(menuConstants.COMMENT_MENU, commentMenuSaga);
}

function* searchMenuWatcher() {
   yield takeLatest(menuConstants.SEARCH_MENU, searchMenu);
}

export default function* rootSaga() {
   yield all([
      getAllMenusWatcher(),
      getPopularMenusWatcher(),
      getCategoryMenusWatcher(),
      getAMenuWatcher(),
      commentMenuSagaWatcher(),
      searchMenuWatcher(),
   ]);
}
