import React, {useState} from 'react';
import './index.scss';
import {Reservations} from '../../../../_components';

import about6 from '../../../../_assets/images/about66.jpg';
// import {ReactComponent as BgIcon} from '../../../../_assets/icons/wlcmBg.svg';

const Section5 = () => {
   const [modalOpen, setModalOpen] = useState(false);
   return (
      <section className="aboutSectionFive container-fluid p-0 position-relative overflow-hidden d-flex align-items-center">
         <div className="bgOverlay position-absolute"></div>
         <div className="bgImg position-absolute">
            <img src={about6} alt="" className="w-100 h-100" />
         </div>
         <div className="content container p-md-0 py-md-5 py-4 my-4">
            <div className="row m-0">
               <div className="col-md-6 col-12 p-md-5 d-flex align-items-center justify-content-center">
                  <p data-aos="zoom-in" data-aos-duration="500" className="title">
                     Do You Have any Meal Plan Today? Reserve Your Table.
                  </p>
               </div>
               <div className="col-md-6 col-12 pt-md-0 pt-5 d-flex align-items-center justify-content-center">
                  <div
                     data-aos="zoom-in"
                     data-aos-duration="500"
                     className="bookTable ps-4 py-5 pe-5 text-lg-start text-center">
                     <p className="subTitle text-capitalize pb-3">book your table</p>
                     <p className="hours pb-2 text-uppercase text-decoration-underline">opening hours</p>
                     <p className="day pb-1">Monday - Saturday</p>
                     <p className="time pb-4">( 7:00 am - 7:00 pm )</p>
                     {/* <p className="eatIn text-decoration-underline">Eat in: Walk ins are welcome</p> */}
                     <p className="freeDelivery pt-3 pb-1">Free Deliveries within Lekki Phase 1</p>
                     <p className="delivery pb-4">Deliveries to VI and Ikoyi - &#8358; 1,000</p>
                     <button onClick={() => setModalOpen(true)} className="reserve">
                        Reservations
                     </button>
                     <Reservations modalOpen={modalOpen} setModalOpen={setModalOpen} />
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Section5;
