import {setupConstants} from '../../_constants';
import {combineReducers} from 'redux';

const IsRequestingSetUp = (state = true, action) => {
   switch (action.type) {
      case setupConstants.REQUEST_SETUP_DATA:
         return true;
      case setupConstants.REQUEST_SETUP_DATA_SUCCESS:
         return false;
      case setupConstants.REQUEST_SETUP_DATA_ERROR:
         return false;
      default:
         return state;
   }
};

const allCategories = (state = [], action) => {
   switch (action.type) {
      case setupConstants.REQUEST_SETUP_DATA_SUCCESS: {
         // return action.data.categories.data;
         return action.data.categories.data.sort((a, b) =>
            a.totalMeals < b.totalMeals ? 1 : b.totalMeals < a.totalMeals ? -1 : 0,
         );
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingSetUp,
   allCategories,
});

export default rootReducer;
