import React, {memo} from 'react';
import classNames from 'classnames';
import {createPortal} from 'react-dom';
import './index.scss';

const FullscreenModal = memo((props) => {
   return props.open
      ? createPortal(
           <div className="fullScreenModal d-flex align-items-center justify-content-center">
              <div onClick={props.onClickAway} className="fullScreenModal__BackDrop" />
              <div className={classNames('fullScreenModal__Contents', props.className)}>{props.children}</div>
           </div>,
           document.body,
        )
      : null;
});

export default FullscreenModal;
