import React from 'react';
import classNames from 'classnames';
import './index.scss';

const NotFound = () => {
   return (
      <div className={classNames('__notFound', {})}>
         <div className="content">NotFound</div>
      </div>
   );
};

export default NotFound;
