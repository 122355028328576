import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {numberWithCommas, capitalizeFirstLetter} from '../../_helpers';
import {cartActions} from '../../_actions';

import logoImg from '../../_assets/images/newLogo.png';
import {ReactComponent as RemoveIcon} from '../../_assets/icons/remove.svg';

const CartRow = ({item}) => {
   const dispatch = useDispatch();
   const [count, setCount] = useState(item?.quantity);
   // console.log('item', item)

   useEffect(() => {
      if (count !== item?.quantity) {
         const data = {
            ...item,
            quantity: count,
            amount: item.price * count,
         };
         dispatch(cartActions.updateItemQuantityInCart(data));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [count]);

   const handleIncrease = useCallback(() => {
      setCount(count + 1);
   }, [count]);

   const handleDecrease = useCallback(() => {
      if (count <= 0) return;
      setCount(count - 1);
   }, [count]);

   const handleRemoveFromCart = (cartItem) => {
      dispatch(cartActions.removeFromCart({_id: cartItem?._id}));
   };

   return (
      <li key={`cart-items-${item.id}`} className="mb-5 row m-0 d-flex align-items-start">
         <div className="col-md-1 col-3 p-0">
            <div className="img">
               {item.img_url ? (
                  <img src={item.img_url} alt="" className="h-100 w-100" />
               ) : (
                  <img src={logoImg} alt="" className="" style={{transform: 'scale(.5)'}} />
               )}
            </div>
         </div>
         <div className="col-md-6 col-9 ps-3">
            <h6 className="desc pb-2">{item.name}</h6>
            <p data-toggle="tooltip" data-placement="top" title={item.description} className="des">
               {capitalizeFirstLetter(item.description)}
            </p>
         </div>
         <div className="col-md-3 col-6 quantity my-3 d-flex align-items-center justify-content-center">
            <button onClick={handleDecrease} className="minus pb-1">
               -
            </button>
            <span className="numb mx-3 d-flex align-items-center justify-content-center">
               {count <= 9 ? `0${count}` : count}
            </span>
            <button onClick={handleIncrease} className="add pb-1">
               +
            </button>
         </div>
         <div className="col-md-2 col-6 p-0 d-flex flex-column align-items-end">
            <p className="price pb-2">&#8358; {numberWithCommas(item.price * count)}</p>
            <p className="remove d-flex align-items-center" onClick={() => handleRemoveFromCart(item)}>
               <RemoveIcon className="me-1" style={{transform: 'scale(.8)'}} />
               Remove
            </p>
         </div>
      </li>
   );
};

export default CartRow;
