import {contactConstants} from '../_constants';

const contactAdmin = (model) => ({
   type: contactConstants.CONTACT_ADMIN,
   data: model,
});

export const contactActions = {
   contactAdmin,
};
