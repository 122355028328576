import React, {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import StarRating from 'react-svg-star-rating';
import CircularProgress from '@material-ui/core/CircularProgress';
import {capitalizeFirstLetter, readableDateString, GetTime} from '../../_helpers';
// import {comments} from '../../_constants';
import LetteredAvatar from 'lettered-avatar';
import Formsy from 'formsy-react';
import {TextArea, TextInput} from '../../_components/CustomInput';

// import {ReactComponent as ReplyIcon} from '../../_assets/icons/replyIcon.svg';
import {blogActions} from '../../_actions';

const BlogComments = ({blog}) => {
   const formRef = useRef(null);
   const dispatch = useDispatch();
   const {IsCommentingABlog, blogCommentSaved} = useSelector((s) => s.blog);
   const comments = blog?.comments;

   const [canSubmit, setCanSubmit] = useState(false);
   const [starRating, setStarRating] = useState(0);

   const handleStarClick = (rating) => {
      setStarRating(rating);
   };

   const handleSubmit = (data) => {
      data['rating'] = starRating;
      data['blogId'] = blog?._id;

      dispatch(blogActions.commentBlog(data));
   };

   useEffect(() => {
      if (blogCommentSaved) {
         formRef.current.reset();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [blogCommentSaved]);

   return (
      <div className="row m-0 py-3 mt-5 comments">
         <div className="col-12 mb-4 px-0">
            <h4 data-aos="fade-up" data-aos-duration="500" className="pb-md-5 pb-4">
               Comments ({comments.length})
            </h4>
            <ul data-aos="fade-up" data-aos-duration="750" className="allComments">
               {comments.map((item) => (
                  <li key={`allComments-${item.id}`} className="row m-0 mb-4 pb-4 d-flex align-items-start">
                     <div className="commentImg col-2 p-0">
                        {item.img ? (
                           <img src={item.img} alt="" className="h-100 w-100" />
                        ) : (
                           <LetteredAvatar
                              name={item.name}
                              options={{
                                 size: 100,
                                 twoLetter: true,
                                 bgColor: '#181818',
                                 imgClass: 'img-fluid user-image',
                                 imgWidth: 100,
                                 imgHeight: 100,
                              }}
                           />
                        )}
                     </div>
                     <div className="col-10">
                        <div className="name__star d-flex flex-wrap align-items-center justify-content-between">
                           <div className="name">
                              <p className="">{capitalizeFirstLetter(item.name)}</p>
                              <small className="date">
                                 {readableDateString(item.date)} at {GetTime(item.date)}
                              </small>
                           </div>
                           <div className="star">
                              <StarRating
                                 unit="half"
                                 size={28}
                                 count={5}
                                 initialRating={item.rating}
                                 activeColor="#FFC403"
                                 hoverColor="#FFC403"
                                 emptyColor="rgba(0, 0, 0, .3)"
                                 roundedCorner={true}
                                 // handleOnClick={handleStarClick}
                                 isReadOnly={true}
                                 innerRadius={20}
                                 outerRadius={45}
                                 starClassName="px-1 each-star"
                                 containerClassName=""
                              />
                           </div>
                        </div>
                        <p className="comment pt-3 pb-2">{item.comment}</p>
                        {/* <div className="reply d-inline-flex align-items-center">
                           <div className="icon me-2 d-flex align-items-center justify-content-center">
                              <ReplyIcon className="w-100 h-100" />
                           </div>
                           <span className="">Reply</span>
                        </div> */}
                     </div>
                  </li>
               ))}
            </ul>
         </div>

         <div className="col-12 px-0">
            <div className="dropComments px-md-5">
               <h4 data-aos="fade-up" data-aos-duration="500" className="pb-4">
                  Leave Your Comment
               </h4>
               <Formsy
                  onValidSubmit={handleSubmit}
                  onValid={() => setCanSubmit(true)}
                  onInvalid={() => setCanSubmit(false)}
                  ref={formRef}
                  data-aos="fade-up"
                  data-aos-duration="750"
                  className="__form">
                  <TextInput
                     id="name"
                     type="text"
                     label="Name"
                     name="name"
                     className="mb-4"
                     placeholder="e.g James Akinyemi"
                     value=""
                  />
                  <TextInput
                     id="email"
                     type="email"
                     label="Email Address"
                     name="email"
                     className="mb-4"
                     placeholder="e.g jamesakinyemi@gmail.com"
                     value=""
                  />
                  <TextArea
                     id="review"
                     type="text"
                     label="Your View"
                     name="comment"
                     className="mb-4"
                     placeholder="Type in your review"
                     value=""
                     rows={6}
                  />
                  <p className="giveRatings">Give your ratings ({starRating})</p>
                  <div className="star mt-3 mb-4">
                     <StarRating
                        unit="half"
                        size={28}
                        count={5}
                        initialRating={starRating}
                        activeColor="#FFC403"
                        hoverColor="#FFC403"
                        emptyColor="rgba(0, 0, 0, .3)"
                        roundedCorner={true}
                        handleOnClick={handleStarClick}
                        // isReadOnly={true}
                        innerRadius={20}
                        outerRadius={45}
                        starClassName="mx-2 each-star"
                        containerClassName=""
                     />
                  </div>
                  <button className="submit" disabled={!canSubmit}>
                     {IsCommentingABlog ? <CircularProgress color="inherit" thickness={1} /> : 'Submit'}
                  </button>
               </Formsy>
            </div>
         </div>
      </div>
   );
};

export default BlogComments;
