import {combineReducers} from 'redux';
import authRootReducer from './auth.reducer';
import blogRootReducer from './blog.reducer';
import cartRootReducer from './cart.reducer';
import contactRootReducer from './contact.reducer';
import forgotPasswordRootReducer from './forgotPassword.reducer';
import menuRootReducer from './menu.reducer';
import orderRootReducer from './order.reducer';
import reservationRootReducer from './reservation.reducer';
import setupRootReducer from './setup.reducer';
import snackbarRootReducer from './snackbar.reducer';
import userRootReducer from './user.reducer';

const rootReducer = combineReducers({
   auth: authRootReducer,
   blog: blogRootReducer,
   cart: cartRootReducer,
   contact: contactRootReducer,
   forgotPassword: forgotPasswordRootReducer,
   menu: menuRootReducer,
   order: orderRootReducer,
   reservation: reservationRootReducer,
   setup: setupRootReducer,
   snackbar: snackbarRootReducer,
   user: userRootReducer,
});

export default rootReducer;
