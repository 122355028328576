import {reservationConstants} from '../_constants';

const createReservation = (model) => ({
   type: reservationConstants.CREATE_RESERVATION,
   data: model,
});

export const reservationActions = {
   createReservation,
};
