import React from 'react';
import './index.scss';

import about2 from '../../../../_assets/images/about22.jpg';
import {ReactComponent as TeaCupIcon} from '../../../../_assets/icons/teaCup.svg';

const Section2 = () => {
   return (
      <section className="aboutSectionTwo container-fluid p-0 overflow-hidden">
         <div className="content container p-md-0 py-md-5 py-4 my-md-4">
            <div className="row m-0 mb-md-0 mb-4">
               <div className="col-lg-6 col-12 p-lg-5 p-md-1 d-flex flex-column justify-content-center">
                  <div className="p-lg-4 p-lg-0 py-4 position-relative">
                     <h1 data-aos="fade-up" data-aos-duration="500" className="pb-md-4 pb-2 text-uppercase">
                        welcome to <br /> lagos tea house
                     </h1>
                     <p data-aos="fade-up" data-aos-duration="750" className="">
                        Healthy eating is inevitable, especially as one gets older but wishes to keep looking younger
                        and healthier. <br /> Using the right combination of ingredients to make delicious meals and
                        drinks would help in providing the right antibodies the body needs as well as increasing
                        metabolism. These will ultimately reduce visits to the doctor's office, aches and pains as well
                        as save a huge ton of money.
                     </p>
                     <div data-aos="fade-up" data-aos-duration="1000" className="">
                        <TeaCupIcon className="teaCupIcon" />
                     </div>
                  </div>
               </div>
               <div className="col-lg-6 col-12 pt-lg-0 pt-5 d-flex align-items-center justify-content-center">
                  <div data-aos="zoom-in" data-aos-duration="1000" className="img">
                     <img src={about2} alt="" className="h-100 w-100" />
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Section2;
