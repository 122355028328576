import {call, put, takeLatest, all} from 'redux-saga/effects';
import {forgotPasswordConstants, userConstants} from '../../_constants';
import {appActions} from '../../_actions';
import {createRequest, setObjectInStorage, createRequestWithToken, parseJSON, checkStatus} from '../../_helpers';

function* getResetPasswordLinkSaga({data}) {
   yield put({type: forgotPasswordConstants.REQUEST_SEND_RESET_PASSWORD_LINK});

   try {
      let resetPasswordLinkUri = `${forgotPasswordConstants.RESET_PASSWORD_LINK_URI}${data.email}/forgot_password`;

      // const curriedReq = yield call(createRequest, resetPasswordLinkUri, {
      //    method: 'POST',
      //    body: JSON.stringify(data),
      // });
      const curriedReq = yield call(createRequest, resetPasswordLinkUri, {
         method: 'GET',
      });

      const response = yield call(fetch, curriedReq);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_SUCCESS,
         resetPasswordLink: jsonResponse,
      });
   } catch (error) {
      let errorRes;
      if (error.response) {
         errorRes = yield call(parseJSON, error.response);
      }
      const errorMessage = errorRes ? errorRes : error;
      // const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_ERROR,
         error: errorMessage,
      });
   }
}

function* resetPasswordSaga({data}) {
   yield put({type: forgotPasswordConstants.REQUEST_RESET_PASSWORD});

   try {
      let passwordResetUri = `${forgotPasswordConstants.RESET_PASSWORD_URI}`;

      const passwordResetReq = createRequestWithToken(passwordResetUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(data.reset_token);

      const response = yield call(fetch, passwordResetReq);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield call(setObjectInStorage, userConstants.USER_KEY, jsonResponse);

      yield put({
         type: forgotPasswordConstants.RESET_PASSWORD_SUCCESS,
         user: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: forgotPasswordConstants.RESET_PASSWORD_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* getResetPasswordLinkSagaWatcher() {
   yield takeLatest(forgotPasswordConstants.SEND_RESET_PASSWORD_LINK, getResetPasswordLinkSaga);
}

function* resetPasswordSagaWatcher() {
   yield takeLatest(forgotPasswordConstants.RESET_PASSWORD, resetPasswordSaga);
}

export default function* rootSaga() {
   yield all([getResetPasswordLinkSagaWatcher(), resetPasswordSagaWatcher()]);
}
