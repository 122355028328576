import {combineReducers} from 'redux';
import {blogConstants} from '../../_constants';
import {updateObject, updateItemInArray} from '../../_helpers';

const IsRequestingBlogs = (state = false, action) => {
   switch (action.type) {
      case blogConstants.REQUEST_ALL_BLOGS:
         return true;
      case blogConstants.REQUEST_ALL_BLOGS_ERROR:
         return false;
      case blogConstants.REQUEST_ALL_BLOGS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsCommentingABlog = (state = false, action) => {
   switch (action.type) {
      case blogConstants.REQUEST_COMMENT_BLOG:
         return true;
      case blogConstants.REQUEST_COMMENT_BLOG_ERROR:
         return false;
      case blogConstants.REQUEST_COMMENT_BLOG_SUCCESS:
         return false;
      default:
         return state;
   }
};

const blogCommentSaved = (state = false, action) => {
   switch (action.type) {
      case blogConstants.REQUEST_COMMENT_BLOG:
         return false;
      case blogConstants.REQUEST_COMMENT_BLOG_ERROR:
         return false;
      case blogConstants.REQUEST_COMMENT_BLOG_SUCCESS:
         return true;
      default:
         return state;
   }
};

const blogDetails = (state = null, {type, blog, comment}) => {
   switch (type) {
      case blogConstants.REQUEST_A_BLOG_SUCCESS:
         return updateObject(state, blog.data);
      case blogConstants.REQUEST_COMMENT_BLOG_SUCCESS:
         return updateObject(state, comment.data);
      default:
         return state;
   }
};

const emptyState = {
   title: '',
   image_uri: '',
   number: '',
   published: '',
};

const blogsList = (state = [], action) => {
   switch (action.type) {
      case blogConstants.REQUEST_ALL_BLOGS_SUCCESS: {
         return action.blogs.data.docs;
      }
      // case blogConstants.REQUEST_ALL_BLOGS_SUCCESS: {
      //    const obj_arr_appended = action.BLOGs.data.map(function (currentValue, Index) {
      //       currentValue.serialNo = Index + 1;
      //       return currentValue;
      //    });
      //    return obj_arr_appended;
      // }
      case blogConstants.REQUEST_ALL_BLOGS_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const recentBlogsList = (state = [], action) => {
   switch (action.type) {
      case blogConstants.REQUEST_RECENT_BLOGS_SUCCESS: {
         return action.recentBlogs.data;
      }
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case blogConstants.REQUEST_ALL_BLOGS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} = action.blogs.data;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      // case blogConstants.CREATE_BLOG_SUCCESS: {
      //    console.log('state', state);
      //    const {hasNextPage, nextPage, totalDocs, limit, page} = state;
      //    const TotalDocs = totalDocs + 1;
      //    const result = {hasNextPage, nextPage, TotalDocs, limit, page};
      //    console.log('result', result);

      //    return updateObject(state, result);
      // }
      // case blogConstants.DELETE_BLOG_SUCCESS: {
      //    console.log('state', state);
      //    const {hasNextPage, nextPage, totalDocs, limit, page} = state;
      //    const TotalDocs = totalDocs - 1;
      //    const result = {hasNextPage, nextPage, TotalDocs, limit, page};
      //    console.log('result', result);

      //    return updateObject(state, result);
      // }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   blogsList,
   recentBlogsList,
   blogDetails,
   IsRequestingBlogs,
   IsCommentingABlog,
   blogCommentSaved,
   pagination,
});

export default rootReducer;
