import React, {useLayoutEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
   About,
   Blog,
   Cart,
   Contact,
   ForgotPassword,
   Home,
   Login,
   Menu,
   Signup,
   Verification,
   ViewItem,
   ViewPost,
} from './Pages';
import NotFound from './404';
import {Footer, Navbar} from './_navigation';
import {appActions} from './_actions';
import {Snackbar} from './_components';
import Aos from 'aos';
import './App.scss';

function App() {
   const dispatch = useDispatch();
   const snackbar = useSelector((s) => s.snackbar);
   const clearSnackBar = () => {
      dispatch(appActions.clearSnackBar());
   };
   console.log('snackbar', snackbar);

   useLayoutEffect(() => {
      Aos.init({duration: 2000});
   }, []);

   return (
      <div className="app">
         <Navbar />
         <div className="wrapper">
            <Routes>
               <Route path="/" element={<Home />} />
               <Route path="/home" element={<Home />} />
               <Route path="/about" element={<About />} />
               <Route path="/menu" element={<Menu />} />
               <Route path="/view-item/:uuid" element={<ViewItem />} />
               <Route path="/blog" element={<Blog />} />
               <Route path="/blog/:uuid" element={<ViewPost />} />
               <Route path="/contact" element={<Contact />} />
               <Route path="/cart" element={<Cart />} />

               <Route path="/email-verification" element={<Verification />} />
               <Route path="/forgot-password" element={<ForgotPassword />} />
               <Route path="/login" element={<Login />} />
               <Route path="/signup" element={<Signup />} />

               <Route path="*" element={<NotFound />} />
            </Routes>
            {snackbar?.message && (
               <Snackbar
                  message={snackbar?.message.text}
                  variant={snackbar?.message.variant}
                  img={snackbar?.message.img}
                  onClose={clearSnackBar}
               />
            )}
         </div>
         <Footer />
      </div>
   );
}

export default App;
