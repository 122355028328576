import {call, put, takeLatest, all} from 'redux-saga/effects';
import {contactConstants} from '../../_constants';
import {appActions} from '../../_actions';
import {createRequest, checkStatus, parseJSON} from '../../_helpers';
//import crashlytics from '@react-native-firebase/crashlytics';

function* contactAdminSaga({data}) {
   yield put({type: contactConstants.REQUEST_CONTACT_ADMIN});

   try {
      const curriedReq = yield call(createRequest, contactConstants.CONTACT_URI, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      // yield call(setObjectInStorage, contactConstants.contact_KEY, jsonResponse);
      yield put({
         type: contactConstants.CONTACT_ADMIN_SUCCESS,
         contact: jsonResponse,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Message successfully sent',
            variant: 'success',
         }),
      );
   } catch (error) {
      let errorRes;
      if (error.response) {
         errorRes = yield call(parseJSON, error.response);
      }
      const errorMessage = errorRes ? errorRes : error;
      yield put({type: contactConstants.CONTACT_ADMIN_ERROR, error: errorMessage});
   }
}

function* contactAdminSagaWatcher() {
   yield takeLatest(contactConstants.CONTACT_ADMIN, contactAdminSaga);
}

export default function* rootSaga() {
   yield all([contactAdminSagaWatcher()]);
}
