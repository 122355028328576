import {appConstants} from '../_constants';

const setSnackBar = (data) => ({
   type: appConstants.SET_SNACKBAR,
   message: data.message,
   variant: data.variant,
   img: data.img,
});

const clearSnackBar = () => ({
   type: appConstants.CLEAR_SNACKBAR,
});

const clearMessages = () => ({
   type: appConstants.CLEAR_MESSAGES,
});

const getStates = () => ({
   type: appConstants.GET_STATES,
});

const getStateData = (model) => ({
   type: appConstants.GET_STATE_DATA,
   data: model,
});

const getStateDataFromStorage = () => ({
   type: appConstants.GET_STATE_DATA_FROM_STORAGE,
});

export const appActions = {
   setSnackBar,
   clearSnackBar,
   clearMessages,
   getStates,
   getStateData,
   getStateDataFromStorage,
};
