import {appConstants} from './app.constant';

const order = 'orders';

export const orderConstants = {
   REQUEST_CREATE_ORDER: 'REQUEST_CREATE_ORDER',
   CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
   CREATE_ORDER_ERROR: 'CREATE_ORDER_ERROR',

   CREATE_ORDER: 'CREATE_ORDER',

   ORDER_URI: `${appConstants.BASE_URI}${order}`,
};
