import React from 'react';
import './index.scss';

import blog1 from '../../_assets/images/blog1.jpg';

const BlogWrapper = ({children}) => {
   return (
      <>
         <section className="blog menuSectionOne container-fluid p-0 position-relative overflow-hidden">
            <div className="bgOverlay position-absolute"></div>
            <div className="img position-absolute">
               <img src={blog1} alt="" className="h-100 w-100" />
            </div>
            <div className="content container p-md-0 h-100 d-flex align-items-center justify-content-center">
               <p data-aos="zoom-in" data-aos-duration="500" className="text-capitalize">
                  our blog
               </p>
            </div>
         </section>

         <section className="blogTwo menuSectionTwo container-fluid p-0 position-relative overflow-hidden">
            {children}
         </section>
      </>
   );
};

export default BlogWrapper;
