import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TextInput} from '../../_components';
import Formsy from 'formsy-react';
import {MDBCol} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import {capitalizeFirstLetter} from '../../_helpers';
import {forgotPasswordActions} from '../../_actions';
import '../Login/index.scss';

// import CircularProgress from '@material-ui/core/CircularProgress';
// import logoImg from '../../_assets/images/logo.png';
// import {ReactComponent as Logo} from '../../_assets/icons/new_logo.svg';

const ForgotPassword = () => {
   const dispatch = useDispatch();
   const {IsSendingResetPasswordLink, errorMessage} = useSelector((s) => s.forgotPassword);

   const [canSubmit, setCanSubmit] = useState(false);
   const [secure, setSecure] = useState(true);

   const toggleSecure = useCallback(() => {
      setSecure((prev) => !prev);
   }, []);

   const disableButton = useCallback(() => {
      setCanSubmit(false);
   }, []);

   const enableButton = useCallback(() => {
      setCanSubmit(true);
   }, []);

   const handleSubmit = (data) => {
      dispatch(forgotPasswordActions.getResetPasswordLink(data));
   };

   return null;
};

export default ForgotPassword;
