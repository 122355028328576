import {call, put, takeLatest, all} from 'redux-saga/effects';
import {userConstants} from '../../_constants';
import {appActions} from '../../_actions';
import {
   createRequest,
   createRequestWithToken,
   clearObjectFromStorage,
   setObjectInStorage,
   checkStatus,
   getObjectFromStorage,
} from '../../_helpers';

function* getUserSaga() {
   try {
      yield put({type: userConstants.GET_REQUEST});

      const user = yield call(getObjectFromStorage, userConstants.USER_KEY);
      // console.log('user!!!!', user);

      if (user) {
         yield put({type: userConstants.GET_SUCCESS, user});
         return;
      }
      yield call(clearObjectFromStorage, userConstants.USER_KEY);

      yield put({type: userConstants.GET_FAILURE});
   } catch (error) {
      yield put({type: userConstants.GET_FAILURE});
   }
}

function* getAllUsersSaga() {
   try {
      yield put({type: userConstants.REQUEST_ALL_USERS});

      const allUsers = yield call(getObjectFromStorage, userConstants.ALL_USER_KEY);

      if (allUsers) {
         yield put({type: userConstants.REQUEST_ALL_USERS_SUCCESS, allUsers});
         return;
      }
      yield call(clearObjectFromStorage, userConstants.ALL_USER_KEY);

      yield put({type: userConstants.REQUEST_ALL_USERS_FAILURE});
   } catch (error) {
      yield put({type: userConstants.REQUEST_ALL_USERS_FAILURE});
   }
}

function* getAllAdminUsers({data}) {
   yield put({type: userConstants.REQUEST_ALL_USERS});

   try {
      const user = yield call(getObjectFromStorage, userConstants.USER_KEY);

      let allUsersUri = `${userConstants.GET_USER_URI}/get_all`;

      const req = createRequestWithToken(allUsersUri, {method: 'GET'})(user?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield call(setObjectInStorage, userConstants.ALL_USER_KEY, jsonResponse);

      yield put({
         type: userConstants.REQUEST_ALL_USERS_SUCCESS,
         allAdminUsers: jsonResponse,
      });
   } catch (error) {
      yield put({type: userConstants.REQUEST_ALL_USERS_ERROR});
   }
}

function* getAdminUsers({data}) {
   yield put({type: userConstants.REQUEST_USERS});

   try {
      const user = yield call(getObjectFromStorage, userConstants.USER_KEY);

      let allUsersUri = `${userConstants.GET_USER_URI}/get_all`;

      if (data?.page) {
         allUsersUri = `${allUsersUri}?page=${data.page + 1}`;
      }

      const req = createRequestWithToken(allUsersUri, {method: 'GET'})(user?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: userConstants.REQUEST_USERS_SUCCESS,
         adminUsers: jsonResponse,
      });
   } catch (error) {
      yield put({type: userConstants.REQUEST_USERS_ERROR});
   }
}

function* exportUsers({data}) {
   yield put({type: userConstants.REQUEST_EXPORT_ALL_USERS});

   try {
      const user = yield call(getObjectFromStorage, userConstants.USER_KEY);

      let usersUri = `${userConstants.GET_USER_URI}/export`;

      const req = createRequestWithToken(usersUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user && user.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: userConstants.REQUEST_EXPORT_ALL_USERS_SUCCESS,
         userData: jsonResponse,
      });
   } catch (error) {
      yield put({type: userConstants.REQUEST_EXPORT_ALL_USERS_ERROR});
   }
}

function* registerAdminUser({data}) {
   yield put({type: userConstants.REQUEST_REGISTER_USER});

   try {
      // const user = yield call(getObjectFromStorage, userConstants.USER_KEY);

      const url = `${userConstants.REGISTER_URI}`;
      const curriedReq = createRequest(url, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: userConstants.REGISTER_USER_SUCCESS,
         user: jsonResponse,
      });

      // yield put(
      //    appActions.setSnackBar({
      //       message: jsonResponse.message
      //          ? jsonResponse.message
      //          : 'User successfully registered',
      //       variant: 'success',
      //    }),
      // );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: userConstants.REGISTER_USER_ERROR,
         error: errorMessage?.errors,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message ? errorMessage.message : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* updateUser({data}) {
   yield put({type: userConstants.REQUEST_UPDATE_USER});

   try {
      const user = yield call(getObjectFromStorage, userConstants.USER_KEY);

      const url = `${userConstants.USER_URI}/${data.uuid}`;

      const req = createRequestWithToken(url, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(user?.access_token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: userConstants.UPDATE_USER_SUCCESS, user: jsonResponse});

      yield put(
         appActions.setSnackBar({
            message: 'User successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: userConstants.UPDATE_USER_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message ? errorMessage.message : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* deleteAdminUser({_id}) {
   try {
      yield put({type: userConstants.REQUEST_DELETE_USER});
      const user = yield call(getObjectFromStorage, userConstants.USER_KEY);

      const url = `${userConstants.GET_USER_URI}/${_id}`;
      const req = createRequestWithToken(url, {
         method: 'DELETE',
         body: JSON.stringify({_id: _id}),
      })(user && user.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      // const jsonResponse = yield call(response.json.bind(response))

      yield put({type: userConstants.DELETE_USER_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: 'Admin user successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: userConstants.DELETE_USER_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* searchAdminUser({data}) {
   yield put({type: userConstants.REQUEST_SEARCH_USER});

   try {
      const user = yield call(getObjectFromStorage, userConstants.USER_KEY);

      let adminUri = `${userConstants.GET_USER_URI}/advanced_search`;

      const adminReq = createRequestWithToken(adminUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user && user.token);
      const response = yield call(fetch, adminReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: userConstants.SEARCH_USER_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: userConstants.SEARCH_USER_SUCCESS,
         adminUser: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: userConstants.SEARCH_USER_ERROR,
         error: errorMessage,
      });
   }
}

function* getUserSagaWatcher() {
   yield takeLatest(userConstants.GET_USER, getUserSaga);
}

function* getAllUsersSagaWatcher() {
   yield takeLatest(userConstants.FETCH_ALL_USERS, getAllUsersSaga);
}

function* exportUsersSagaWatcher() {
   yield takeLatest(userConstants.EXPORT_USERS, exportUsers);
}

function* getAllAdminUsersSagaWatcher() {
   yield takeLatest(userConstants.GET_ALL_USERS, getAllAdminUsers);
}

function* getAdminUsersSagaWatcher() {
   yield takeLatest(userConstants.GET_USERS, getAdminUsers);
}

function* registerAdminUserSagaWatcher() {
   yield takeLatest(userConstants.REGISTER_USER, registerAdminUser);
}

function* updateUserSagaWatcher() {
   yield takeLatest(userConstants.UPDATE_USER, updateUser);
}

function* deleteAdminUserSagaWatcher() {
   yield takeLatest(userConstants.DELETE_USER, deleteAdminUser);
}

function* searchAdminUserWatcher() {
   yield takeLatest(userConstants.SEARCH_USER, searchAdminUser);
}

export default function* rootSaga() {
   yield all([
      getUserSagaWatcher(),
      getAllUsersSagaWatcher(),
      exportUsersSagaWatcher(),
      getAllAdminUsersSagaWatcher(),
      getAdminUsersSagaWatcher(),
      registerAdminUserSagaWatcher(),
      updateUserSagaWatcher(),
      deleteAdminUserSagaWatcher(),
      searchAdminUserWatcher(),
   ]);
}
