import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Formsy from 'formsy-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {TextArea, TextInput} from '../../_components/CustomInput';
import './index.scss';

// import menuBg from '../../_assets/images/menuBg.png';
import {ReactComponent as PhoneIcon} from '../../_assets/icons/phone2.svg';
import {ReactComponent as LocationIcon} from '../../_assets/icons/location2.svg';
import {ReactComponent as MailIcon} from '../../_assets/icons/email2.svg';
import {contactActions} from '../../_actions';

const Contact = () => {
   const dispatch = useDispatch();
   const {IsContactingAdmin, adminContactedSuccessfully} = useSelector((s) => s.contact);

   const [canSubmit, setCanSubmit] = useState(false);

   const handleSubmit = (data) => {
      dispatch(contactActions.contactAdmin(data));
   };

   return (
      <>
         <section className="__contact container-fluid p-0 d-flex align-items-center justify-content-center overflow-hidden">
            <p data-aos="zoom-in" data-aos-duration="500" className="title">
               Feel Free to Contact Us
            </p>
         </section>

         <section className="contactSectionTwo container-fluid p-0 position-relative overflow-hidden">
            <div className="content container p-md-0 py-md-5 py-4 my-4">
               <div className="row m-0 h-100">
                  <div data-aos="fade-up" data-aos-duration="500" className="col-md-5 col-12 pe-lg-5 mb-md-0 mb-5">
                     <div className="contactDetails p-lg-5 p-3">
                        <h4 className="pb-4">Our Contact details</h4>
                        <ul className="contact">
                           <li className="mb-5 d-flex align-items-start">
                              <div className="icon me-3">
                                 <PhoneIcon className="h-100 w-100" />
                              </div>
                              <div className="">
                                 <h6 className="head pb-2">Phone</h6>
                                 <a href="tel:+2348187934282" className="text-decoration-none">
                                    +234 818 793 4282
                                 </a>
                              </div>
                           </li>
                           <li className="mb-5 d-flex align-items-start">
                              <div className="icon2 me-md-4 me-3">
                                 <LocationIcon className="h-100 w-100" />
                              </div>
                              <div className="">
                                 <h6 className="head pb-2">Address</h6>
                                 <p className="text-start">
                                    Lagos Tea House, Plot 6, Admiralty Way Lekki Phase 1, (the ash building beside UBA
                                    and packtonian hotel) Lagos, Nigeria.
                                 </p>
                              </div>
                           </li>
                           <li className="mb-5 d-flex align-items-start">
                              <div className="icon me-3">
                                 <MailIcon className="h-100 w-100" />
                              </div>
                              <div className="">
                                 <h6 className="head pb-2">Email Us</h6>
                                 <a href="mailto:lagosteahouse@gmail.com" className="text-decoration-none">
                                    lagosteahouse@gmail.com
                                 </a>
                              </div>
                           </li>
                        </ul>
                     </div>
                  </div>

                  <div data-aos="fade-up" data-aos-duration="750" className="col-md-7 col-12">
                     <div className="yourMessage p-lg-5 p-3">
                        <h4 className="pb-4">Leave Your Comment</h4>
                        <Formsy
                           onValidSubmit={handleSubmit}
                           onValid={() => setCanSubmit(true)}
                           onInvalid={() => setCanSubmit(false)}
                           className="__form">
                           <div className="mb-4 row m-0 d-flex align-items-enter justify-content-between">
                              <TextInput
                                 id="name"
                                 type="text"
                                 label="Full Name"
                                 name="fullname"
                                 className="col-md-6 ps-0 pe-md-0 pe-0 mb-md-0 mb-3"
                                 placeholder="e.g James Akinyemi"
                              />
                              <TextInput
                                 id="email"
                                 type="email"
                                 label="Email Address"
                                 name="email"
                                 className="col-md-6 pe-0 ps-md-3 ps-0"
                                 placeholder="e.g jamesakinyemi@gmail.com"
                              />
                           </div>
                           <div className="mb-4 row m-0 d-flex align-items-enter justify-content-between">
                              <TextInput
                                 id="number"
                                 type="text"
                                 label="Phone Number"
                                 name="phone"
                                 className="col-md-6 ps-0 pe-md-0 pe-0 mb-md-0 mb-3"
                                 placeholder=""
                              />
                              <TextInput
                                 id="subject"
                                 type="text"
                                 label="Subject"
                                 name="subject"
                                 className="col-md-6 pe-0 ps-md-3 ps-0"
                                 placeholder=""
                              />
                           </div>
                           <TextArea
                              id="review"
                              type="text"
                              label="Your Message"
                              name="message"
                              className="mb-4"
                              placeholder="Type in your message"
                              rows={6}
                           />
                           <div className="d-flex justify-content-center">
                              <button className="submit w-50" disabled={!canSubmit}>
                                 {IsContactingAdmin ? <CircularProgress color="inherit" thickness={1} /> : 'Submit'}
                              </button>
                           </div>
                        </Formsy>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Contact;
